import styled from "styled-components";

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  position: relative;
  min-width: 50px;
  min-height: 50px;
  :before {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 67, 255, 0.1);
    border-radius: 100px;
    position: absolute;
    top: -18px;
    right: -18px;
  }
  svg {
    margin-bottom: 30px;
    fill: #0043ff;
    opacity: 1;
  }
  @media (max-width: 768px) {
    :before {
      width: 31px;
      height: 31px;
      top: -10px;
      right: 3px;
    }
  }
`;

export const Icon = styled.img`
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
    height: 30px;
  }
`;

export const Title = styled.div`
  color: #0043ff;
  font-size: 1.82rem;
  font-weight: 500;
  max-width: 200px;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.08rem;
  }
`;

export const MetierCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: #d7e2ff;
  height: 320px;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
  .link {
    display: none;
  }
  :hover {
    background-color: #1f1f98;
    padding-top: 60px;
    ${DataContainer} {
      justify-content: flex-start;
    }
    ${Title} {
      color: #fafbff;
    }
    ${IconContainer} {
      :before {
        display: none;
      }
      svg,
      g {
        fill: #fafbff;
        opacity: 1;
      }
    }
    .link {
      display: inline-flex;
    }
  }

  @media (max-width: 768px) {
    :hover {
      background-color: #1f1f98;
      padding-top: 40px;
    }
    height: 130px;
    padding: 30px 25px 25px 25px;
    justify-content: center;
  }
`;
