import styled from "styled-components";

export const PressCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 125px;
  grid-row-gap: 90px;
  padding: 160px 0 0 0;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
    grid-column-gap: 0;
    grid-row-gap: 25px;
    padding: 60px 0 0 0;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .paginator {
    margin-top: 120px;
  }
  @media (max-width: 768px) {
    .paginator {
      margin-top: 40px;
    }
  }
`;
