import { gql } from "apollo-boost";

export const GET_EVENTS_PAGE_DATA = gql`
  {
    page(id: "cGFnZToyMjA=") {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    evenements {
      nodes {
        id
        slug
        informationsEvenement {
          dateDebut
          dateFin
          lieu
          theme
          titre
          type
          visuelEvenement {
            altText
            sourceUrl
          }
        }
      }
    }
    ia: page(id: "cGFnZToyMTQ=") {
      institutAmadeus {
        auFilDuTemps {
          faitsMarquants {
            date
            titre
            contenu
            visuel {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
