import { gql } from "apollo-boost";

export const GET_MENTIONS_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
      blocContenu {
        blocsContenus {
          ... on Page_Bloccontenu_BlocsContenus_Titre {
            contenuTitre
            niveau
          }
          ... on Page_Bloccontenu_BlocsContenus_Paragraphe {
            paragraphe
          }
          ... on Page_Bloccontenu_BlocsContenus_GalerieImages {
            images {
              altText
              sourceUrl
            }
          }
          ... on Page_Bloccontenu_BlocsContenus_Image {
            image {
              altText
              sourceUrl
            }
          }
          ... on Page_Bloccontenu_BlocsContenus_Video {
            idVideo
            miniatureVideo {
              altText
              sourceUrl
            }
          }
          ... on Page_Bloccontenu_BlocsContenus_Bouton {
            libelleBouton
            typeDeLien
            lienExterne
            lienInterne {
              ... on Page {
                slug
              }
            }
            fichier {
              mediaItemUrl
            }
            icone {
              altText
              sourceUrl(size: LARGE)
              srcSet
            }
          }
          ... on Page_Bloccontenu_BlocsContenus_Espaceur {
            taille
          }
        }
      }
    }
  }
`;
