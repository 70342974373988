import React, { useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { useHistory } from "react-router-dom";

import { TopbarWrapper, LangItemLink } from "./styles/TopbarStyles";

const Topbar = (props) => {
  let history = useHistory();
  const { topbarData, navigateTo } = useContext(GlobalContext);
  const { menuItems, loading, error } = topbarData;

  useEffect(() => {
    error && console.log("Topbar data loading error: ", error.message);
  }, [error]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <TopbarWrapper className="topbar">
          {menuItems &&
            menuItems.map((item, i) => (
              <LangItemLink href={item.url} key={i}>
                {item.label}
              </LangItemLink>
            ))}
        </TopbarWrapper>
      </React.Fragment>
    );
  }, [topbarData]);
};

Topbar.propTypes = {
  menuItems: PropTypes.array,
};

Topbar.defaultProps = {
  menuItems: [],
};

export default Topbar;
