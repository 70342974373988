import styled from "styled-components";

export const TeamCarouselWrapper = styled.div`
  .rec-carousel {
    justify-content: center;
  }
  .rec-slider-container {
    width: 1140px;
  }
  .rec-item-wrapper {
    height: 100%;
  }
`;

export const CarouselArrows = styled.div`
  display: flex;
  img {
    cursor: pointer;
  }
  img.prev {
    transform: rotate(180deg);
    margin-right: 45px;
  }
  img.next {
    margin-left: 45px;
  }
  @media (max-width: 768px) {
    img {
      width: 18px;
    }
    img.prev {
      margin-right: 10px;
      margin-left: 20px;  
    }
    img.next {
      margin-left: 10px;
      margin-right: 20px;  
    }
  }
`;
