import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 11px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: 'Rubik', serif;
    background: #f1f5ff;
    overflow-y: scroll;
  }

  .App {
    max-width: 100%;
    overflow:hidden;
  }

  a{
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }

  button {
    font-family: 'Rubik', serif;
    :focus {
      outline: none;
    }
  }

  h1{
    color: #fafbff;
    margin: 0;
    font-size: 4.8rem;
    font-weight: 500;
    b {
      font-size: 6.7rem;
      font-weight: bold;
    }
    @media (max-width: 768px) {
      font-size: 1.09rem;
    }
  }

  h2 {
    color: #043DBA;
    font-size: 3.75rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 4.58rem;
    margin: 0;
    position: relative;
    p {
      color: #043DBA;
      font-size: 3.75rem;
      font-weight: 400;
    }
    &.colored_bg {
      background-color: #F1F5FF;
      width: fit-content;
      padding: 60px 30px;
    }
    &.secondary {
      color: #02C5FF;
    }
    &.withline {
      :before {
        content: '';
        width: 1000px;
        left: -1020px;
        height: 2px;
        border: 2px solid #043dba;
        position: absolute;
        bottom: 10px;
      }
    }
    @media (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.83rem;
      p {
        font-size: 1.4rem;
      }
    }
  }

  p {
    color: #02203C;
    font-size: 1.6rem;
    line-height: 2.33rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      line-height: 1.8rem;
      font-size: 1.2rem;
    }
  }

  b, strong {
    font-weight: 500;
  }

  .main-container {
    padding-top: 175px;
    background: #fff;
    @media (max-width: 768px) {
      padding-top: 60px;
    }
  }

  .content-container {
    padding-top: 65px;
    padding-left: 20px;
    padding-right: 20px;
    &.white-bg {
      background: #fff;
      padding-bottom: 65px;
      @media (max-width: 768px) {
        padding-bottom: 30px;
      } 
    }
    &.no-title {
      padding-top: 200px;
      @media (max-width: 768px) {
        padding-top: 60px;
      }
    }
    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      &.noHorizontalPad {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }

  .container {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .fixed-container {
    width: 1140px;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }
  }

`;

export default GlobalStyles;
