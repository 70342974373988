import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";

const Seo = ({ metaTitle, metaDesc }) => {
  return (
    <Helmet>
        <title>{metaTitle ? metaTitle : "INSTITUT AMADEUS"}</title>
        <meta name="description" content={metaDesc ? metaDesc : "Portail web de l'INSTITUT AMADEUS" } />
    </Helmet>
  );
};

export default Seo;


