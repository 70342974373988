import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useQuery } from "@apollo/react-hooks";
import { GET_CAREERS_PAGE_DATA } from "graphql/get_careers_page_data";
import { GlobalContext } from "GlobalContext";
import {
  Title,
  Content,
  JobOffersContainer,
  StageOffersContainer,
  OffersWrapper,
  OffersList,
  H2,
  CtaWrapper,
  Text,
  EmailLink,
} from "./styles/CareersStyles";

import CareerOfferCard from "components/CareerOfferCard";

const Careers = (props) => {
  let offresEmploi = [];
  let offresStage = [];
  let intro = null;
  let seo = null;
  let contenuCandidatureSpontanee = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData, currentLang } =
    useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE2" : "cGFnZToyMjQ=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_CAREERS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("Careers page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  if (pageGqlData) {
    offresEmploi = [...pageGqlData.offres_emploi.nodes].filter(
      (offre) => offre.offre_emploi.typeOffre !== "stage"
    );
    offresStage = [...pageGqlData.offres_emploi.nodes].filter(
      (offre) => offre.offre_emploi.typeOffre === "stage"
    );
    intro = pageGqlData.page.heroPage.introduction;
    seo = pageGqlData.page.seo;
    contenuCandidatureSpontanee =
      pageGqlData.page.carrieres.contenuCandidatureSpontanee;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            {/* <div className="fixed-container">
              <div className={`content-container white-bg no-title`}>
                <div className="container">
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: "Notre politique RH",
                    }}
                  />
                  <Content
                    dangerouslySetInnerHTML={{
                      __html:
                        "<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic reiciendis dolor molestias maiores quos nesciunt accusantium perferendis facilis architecto, dolore obcaecati maxime harum. Tempora odit est impedit, nulla doloremque animi</p>",
                    }}
                  />
                </div>
              </div>
            </div> */}
            <div className="fixed-container">
              {intro && (
                <div className={`content-container white-bg no-title`}>
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                  </div>
                </div>
              )}
              <OffersWrapper>
                {offresEmploi.length > 0 && (
                  <JobOffersContainer>
                    <H2
                      className="withline"
                      dangerouslySetInnerHTML={{ __html: "Offres d'emploi" }}
                    />
                    <OffersList>
                      {offresEmploi.map((offre, i) => (
                        <CareerOfferCard data={offre} type="job" key={i} />
                      ))}
                    </OffersList>
                  </JobOffersContainer>
                )}
                {offresStage.length > 0 && (
                  <StageOffersContainer>
                    <H2
                      className="withline"
                      dangerouslySetInnerHTML={{ __html: "Offres de stage" }}
                    />
                    <OffersList>
                      {offresStage.map((offre, i) => (
                        <CareerOfferCard data={offre} type="stage" key={i} />
                      ))}
                    </OffersList>
                  </StageOffersContainer>
                )}
              </OffersWrapper>
            </div>
            <CtaWrapper>
              <div className="fixed-container">
                <Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contenuCandidatureSpontanee,
                    }}
                  />
                </Text>
              </div>
            </CtaWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [activeMegaMenu, isLoading]);
};

Careers.propTypes = {};

Careers.defaultProps = {};

export default Careers;
