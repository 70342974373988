import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";

import {
  TrainingCardWrapper,
  DataContainer,
  Title,
  SubTitle,
  MoreLinkContainer,
  MoreLink,
} from "./styles/TrainingCardStyles";

const TrainingCard = ({ data, odd }) => {
  const { navigateTo } = useContext(GlobalContext);
  const { title, slug, formationsPackagees } = data;

  return (
    <React.Fragment>
      <TrainingCardWrapper
        className="formation-card"
        odd={odd}
        onClick={() => navigateTo("/formations/formations-packagees/" + slug)}
      >
        <DataContainer>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          {formationsPackagees && formationsPackagees.sousTitre && (
            <SubTitle
              dangerouslySetInnerHTML={{
                __html: formationsPackagees.sousTitre,
              }}
            />
          )}
        </DataContainer>
        <MoreLinkContainer>
          <MoreLink />
        </MoreLinkContainer>
      </TrainingCardWrapper>
    </React.Fragment>
  );
};

TrainingCard.propTypes = {};

TrainingCard.defaultProps = {};

export default TrainingCard;
