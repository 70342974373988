import styled from "styled-components";

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  ul.pagination {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    li {
      a {
        display: flex;
        align-items: center;
        padding: 10px;
        color: #043dba;
        margin: 0 7px;
        cursor: pointer;
        font-size: 1.2rem;
        outline: none;
        transition: 0.3s;
        @media (max-width: 768px) {
          margin: 0;
          font-size: .9rem;
          padding: 4px;
        }
      }
      a:hover,
      &.active a {
        background: #043dba;
        color: #fafbff;
      }
      &.previous {
        margin-right: 46px;
        @media (max-width: 768px) {
          margin-right: 6px;
        }
        a {
          :before {
            content: "";
            display: block;
            position: relative;
            min-width: 26px;
            height: 18px;
            margin-right: 10px;
            margin-bottom: 2px;
            background-image: url(${(props) => props.blueArrow});
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
            transition: 0.3s;
            @media (max-width: 768px) {
              min-width: 16px;
              height: 10px;
              margin-right: 6px;
              margin-bottom: 0;
            }
          }
          :hover:before {
            background-image: url(${(props) => props.whiteArrow});
          }
        }
      }
      &.next {
        margin-left: 46px;
        @media (max-width: 768px) {
          margin-left: 6px;
        }
        a {
          :after {
            content: "";
            display: block;
            position: relative;
            min-width: 26px;
            height: 18px;
            margin-left: 10px;
            margin-bottom: 2px;
            background-image: url(${(props) => props.blueArrow});
            background-repeat: no-repeat;
            background-size: contain;
            transition: 0.3s;
            @media (max-width: 768px) {
              min-width: 16px;
              height: 10px;
              margin-left: 6px;
              margin-bottom: 0;
            }
          }
          :hover:after {
            background-image: url(${(props) => props.whiteArrow});
          }
        }
      }
    }
  }
`;
