import styled from "styled-components";

export const NewsCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 210px;
  flex-direction: column;
  padding: 32px 25px;
  min-height: 100%;
  margin-top: -20px;
  background: #0043ff;
  color: #fafbff;
  font-size: 1.67rem;
  font-weight: 300;
  text-align: right;
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    min-height: auto;
    padding: 8px 5px;
    margin-top: 0;
    min-width: auto;
    font-size: 1.27rem;
  }
`;

export const DayMonth = styled.span`
  white-space: nowrap;
  display: flex;
  margin-bottom: 5px;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 1.09rem;
  }
`;

export const Sep = styled.div`
  width: 30px;
  height: 18px;
  margin: 0 2px;
  border-bottom: 1px solid;
  color: #fafbff;
  @media (max-width: 768px) {
    height: 10px;
    margin: 0 3px;
  }
`;

export const Year = styled.span``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  @media (max-width: 768px) {
    padding: 12px 10px 3px;
  }
`;

export const Title = styled.div`
  color: #092063;
  font-size: 1.5rem;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.18rem;
    line-height: 1.6rem;
  }
`;

export const Excerpt = styled.div`
  color: #02203c;
  font-size: 1.08rem;
  font-weight: 300;
  margin-top: 15px;
`;
