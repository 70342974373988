import { gql } from "apollo-boost";

export const GET_EVENT_DETAILS_DATA = gql`
  query($slug: String!) {
    evenementBy(slug: $slug) {
      id
      title
      informationsEvenement {
        dateDebut
        dateFin
        lieu
        theme
        titre
        type
        visuelEvenement {
          altText
          sourceUrl
        }
      }
      blocContenu {
        blocsContenus {
          ... on Evenement_Bloccontenu_BlocsContenus_Titre {
            contenuTitre
            niveau
          }
          ... on Evenement_Bloccontenu_BlocsContenus_Paragraphe {
            paragraphe
          }
          ... on Evenement_Bloccontenu_BlocsContenus_Image {
            image {
              altText
              sourceUrl
            }
          }
          ... on Evenement_Bloccontenu_BlocsContenus_GalerieImages {
            images {
              altText
              sourceUrl
            }
          }
          ... on Evenement_Bloccontenu_BlocsContenus_Video {
            idVideo
            miniatureVideo {
              altText
              sourceUrl
            }
          }
          ... on Evenement_Bloccontenu_BlocsContenus_Bouton {
            libelleBouton
            typeDeLien
            lienExterne
            lienInterne {
              ... on Page {
                slug
              }
            }
            fichier {
              mediaItemUrl
            }
            icone {
              altText
              sourceUrl(size: LARGE)
              srcSet
            }
          }
          ... on Evenement_Bloccontenu_BlocsContenus_Espaceur {
            taille
          }
        }
      }
      programmeEvenement {
        programme {
          heureDebut
          heureFin
          titre
          contenu
          moderateur
          nomPrenomModerateur
          fonctionOrganismeModerateur
          intervenants
          titreIntervenants
          intervenant {
            nomPrenomSpeaker
            fonctionOrganismeSpeaker
          }
        }
      }
    }
  }
`;
