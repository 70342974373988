import React, { useState } from "react";
import PropTypes from "prop-types";
import GalleryCarousel from "components/GalleryCarousel";
import VideoPlayer from "components/Header/VideoPlayer";
import VideoCover from "components/VideoCover";
import {
  ContentWrapper,
  ImageContainer,
  Space,
  ButtonContainer,
  ButtonBlock,
} from "./styles/ContentBlockStyles";

const ContentBlock = ({ block }) => {
  const [showVideo, setShowVideo] = useState(false);
  const renderSpace = (size) => {
    switch (size) {
      case "small":
        return <Space size="small" />;
      case "medium":
        return <Space size="medium" />;
      case "large":
        return <Space size="large" />;
      default:
        return <Space size="small" />;
    }
  };
  const getLink = (block) => {
    switch (block.typeDeLien) {
      case "fichier":
        return block.fichier.mediaItemUrl;
      case "interne":
        return "/" + block.lienInterne[0].slug;
      case "externe":
        return block.lienExterne;
      default:
        return "#";
    }
  };
  return (
    <React.Fragment>
      <ContentWrapper className="content-block">
        {
          // Titre
          block && block.contenuTitre && (
            <div className="container">
              <block.niveau
                dangerouslySetInnerHTML={{ __html: block.contenuTitre }}
              />
            </div>
          )
        }

        {
          // Paragraph
          block && block.paragraphe && (
            <div className="container wysiwyg">
              <span dangerouslySetInnerHTML={{ __html: block.paragraphe }} />
            </div>
          )
        }

        {
          // Image
          block && block.image && (
            <ImageContainer>
              <img src={block.image.sourceUrl} alt={block.image.altText} />
            </ImageContainer>
          )
        }

        {
          // Gallerie d'image
          block && block.images && <GalleryCarousel images={block.images} />
        }

        {
          // Video
          block &&
            block.idVideo &&
            (showVideo ? (
              <VideoPlayer
                videoId={block.idVideo}
                setShowVideo={setShowVideo}
              />
            ) : (
              <VideoCover
                bg={block.miniatureVideo.sourceUrl}
                setShowVideo={setShowVideo}
              />
            ))
        }

        {
          // Bouton
          block && block.libelleBouton && (
            <div className="container">
              <ButtonContainer>
                <ButtonBlock
                  href={getLink(block)}
                  target={block.typeDeLien === "interne" ? "" : "_blank"}
                  download={block.typeDeLien === "fichier"}
                >
                  {block.icone && (
                    <img src={block.icone.sourceUrl} alt="icon" />
                  )}
                  <span>{block.libelleBouton}</span>
                </ButtonBlock>
              </ButtonContainer>
            </div>
          )
        }

        {
          // Espace
          block && block.taille && (
            <div className="container">{renderSpace(block.taille)}</div>
          )
        }
      </ContentWrapper>
    </React.Fragment>
  );
};

ContentBlock.propTypes = {};

ContentBlock.defaultProps = {};

export default ContentBlock;
