import React from "react";
import PropTypes from "prop-types";
import { PaginatorWrapper } from "./styles/PaginatorStyles";
import ReactPaginate from "react-paginate";

import arrowSVGblue from "images/fleche-lire-plus.svg";
import arrowSVGwhite from "images/fleche-lire-plus-white.svg";

const Paginator = ({ data, perPage, handlePageClick }) => {
  const pageCount = data.length / perPage;
  return pageCount > 1 ? (
    <React.Fragment>
      <span></span>
      <PaginatorWrapper
        className="paginator"
        blueArrow={arrowSVGblue}
        whiteArrow={arrowSVGwhite}
      >
        <ReactPaginate
          previousLabel={"Précédent"}
          nextLabel={"Suivant"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(e) => handlePageClick(e)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </PaginatorWrapper>
    </React.Fragment>
  ) : null;
};

Paginator.propTypes = {};

Paginator.defaultProps = {};

export default Paginator;
