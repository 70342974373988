import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { parseDate, getDay, getMonth, getYear } from "utils";
import { isMobile } from "react-device-detect";
import {
  TimelineItemWrapper,
  EventDataContainer,
  DetailsContainer,
  EventTitle,
  EventDescription,
  DateContainer,
} from "./styles/TimelineItemStyles";

const TimelineItem = ({ active, data, onClick }) => {
  const { dateDebut, dateFin, lieu, theme, titre, type } = data;
  const { currentLang } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <TimelineItemWrapper
        className="timeline-item"
        active={active}
        onClick={onClick}
      >
        <DateContainer>
          <b>{getDay(parseDate(dateDebut))}</b>
          <span>
            {getMonth(parseDate(dateDebut), currentLang === "en" ? "en" : "fr")}
          </span>
          <span>
            {getYear(parseDate(dateDebut), currentLang === "en" ? "en" : "fr")}
          </span>
        </DateContainer>
        <EventDataContainer>
          <DetailsContainer>
            {type && (
              <span style={{ textTransform: "capitalize" }}>{type}</span>
            )}
            {!isMobile && type && <span> </span>}
            {!isMobile && lieu && <span>{"| "}</span>}
            {lieu && <b dangerouslySetInnerHTML={{ __html: lieu }} />}
          </DetailsContainer>
          <EventTitle dangerouslySetInnerHTML={{ __html: titre }} />
          <EventDescription dangerouslySetInnerHTML={{ __html: theme }} />
        </EventDataContainer>
      </TimelineItemWrapper>
    </React.Fragment>
  );
};

TimelineItem.propTypes = {};

TimelineItem.defaultProps = {};

export default TimelineItem;
