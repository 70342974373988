import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { getDay, getMonth, getYear } from "utils";

import {
  PostCardWrapper,
  Image,
  DetailsContainer,
  DateContainer,
  DayMonth,
  Sep,
  Year,
  Content,
  Title,
  Excerpt,
} from "./styles/PostCardStyles";

const PostCard = ({ showExcerpt, data }) => {
  const { navigateTo, currentLang } = useContext(GlobalContext);
  const { slug, title, date, publications, publicationCategories } = data;
  let category = publicationCategories
    ? publicationCategories.nodes[0].slug
    : "uncategorized";
  let paragraphs = null;
  data.blocContenu &&
    data.blocContenu.blocsContenus &&
    (paragraphs = data.blocContenu.blocsContenus
      .filter((block) => block.paragraphe && true)
      .map((block) => {
        return block.paragraphe;
      }));
  const excerpt =
    paragraphs && paragraphs[0]
      ? paragraphs[0]
          .split("<p>")
          .join("")
          .split("</p>")
          .join("")
          .substr(0, 280) + "..."
      : null;
  const day = getDay(date);
  const month = getMonth(date, currentLang === "en" ? "en" : "fr");
  return (
    <React.Fragment>
      <PostCardWrapper
        className="post-card"
        onClick={() => navigateTo("/publications/" + category + "/" + slug)}
      >
        {publications.global && publications.global.visuelPublication && (
          <Image
            src={publications.global.visuelPublication.sourceUrl}
            alt={publications.global.visuelPublication.altText}
          />
        )}
        <DetailsContainer>
          <DateContainer>
            <DayMonth>
              {currentLang === "en" ? month : day}
              <Sep />
              {currentLang === "en" ? day : month}
            </DayMonth>
            <Year>{getYear(date)}</Year>
          </DateContainer>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            {/* {showExcerpt && excerpt && (
              <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
            )} */}
          </Content>
        </DetailsContainer>
      </PostCardWrapper>
    </React.Fragment>
  );
};

PostCard.propTypes = {
  showExcerpt: PropTypes.bool,
};

PostCard.defaultProps = {
  showExcerpt: false,
};

export default PostCard;
