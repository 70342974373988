import { gql } from "apollo-boost";

export const GET_FORMATION_PACKAGEE_DETAILS_PAGE_DATA = gql`
  query($slug: String!, $pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    formation_packageeBy(slug: $slug) {
      title
      formationsPackagees {
        sousTitre
        imageHero {
          sourceUrl
        }
        descriptif
        objectifs
        cible
        dureeDeLaFormation
        images {
          sourceUrl
        }
        modules {
          titreModule
          detailModule
        }
        contactFormation
      }
    }
  }
`;
