import styled from "styled-components";

export const NavMenuWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 16%;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const Logo = styled.img`
  height: 80px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 40px;
  }
`;

export const NavMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.div`
  padding: 5px;
  width: 81%;
  min-width: 700px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuItem = styled.a`
  padding: 0 18px;
  color: #fafbff;
  font-size: 1.08rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
`;

export const SocialIcons = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    margin-left: 14px;
  }
`;
