import styled from "styled-components";

export const CareerDetailsWrapper = styled.div`
  .details-container {
    flex-direction: row;
  }
  .content-block {
    margin-bottom: 65px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
      p {
        margin-bottom: 15px;
      }
    }
  }
  h2 {
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
  span {
    color: #fff;
  }
  p {
    padding-left: 28px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
  ul {
    list-style: none;
    padding-left: 28px;
    margin: 0;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      padding-left: 10px;
    }
    li {
      display: block;
      line-height: 2.66rem;
      font-size: 1.6rem;
      padding: 8px 0;
      padding-left: 22px;
      position: relative;
      :before {
        content: "";
        width: 12px;
        height: 12px;
        background: rgba(0, 67, 255, 0.3);
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        border-radius: 100px;
        position: absolute;
        top: 18px;
        left: 0;
      }
      :after {
        content: "";
        width: 8px;
        height: 8px;
        background: #0043ff;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        border-radius: 100px;
        position: absolute;
        top: 20px;
        left: 2px;
      }
      @media (max-width: 768px) {
        line-height: 1.8rem;
        font-size: 1.2rem;
        padding: 5px 0;
        padding-left: 22px;
        :before {
          top: 0.7rem;
        }
        :after {
          top: 0.9rem;
        }
      }
    }
    ul {
      margin-top: 15px;
      margin-bottom: 0;
      padding-left: 0;
      li {
        font-size: 1.5rem;
        padding: 2px 0;
        padding-left: 20px;
        position: relative;
        :before {
          content: "••";
          color: rgba(0, 67, 255, 0.3);
          width: 10px;
          height: 32px;
          background: transparent;
          position: absolute;
          top: 2px;
          left: 0;
        }
        :after {
          display: none;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .place:before {
      content: "|";
      width: 15px;
      display: inline-flex;
      justify-content: center;
    }
  }
`;

export const EventCardWrapper = styled.div`
  .event-card {
    position: relative;
    background-color: rgba(0, 67, 255, 0.95);
    align-items: flex-end;
    .title {
      color: #fafbff;
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 50px;
    }
    @media (max-width: 768px) {
      .title {
        font-size: 1.25rem;
        margin-right: 0;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
`;

export const OfferContent = styled.div`
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const CtaWrapper = styled.div`
  background: #0043ff;
  padding: 120px 0;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 30px 0;
  }
`;

export const Text = styled.div`
  color: #fafbff;
  font-size: 3.33rem;
  line-height: 5rem;
  text-align: center;
  margin-bottom: 50px;
  padding: 0;
  p,
  a {
    color: #fafbff;
    font-size: 3.33rem;
    line-height: 5rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 15px;
    p,
    a {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
`;

export const EmailLink = styled.div`
  color: #fafbff;
  text-align: center;
  text-decoration: underline;
`;
