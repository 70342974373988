import { gql } from "apollo-boost";

export const GET_NEWS_DETAILS_DATA = gql`
  query($slug: String!, $pageId: ID!) {
    page(id: $pageId) {
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
    }
    actualiteBy(slug: $slug) {
      id
      title
      date
      slug
      blocContenu {
        blocsContenus {
          ... on Actualite_Bloccontenu_BlocsContenus_Titre {
            contenuTitre
            niveau
          }
          ... on Actualite_Bloccontenu_BlocsContenus_Paragraphe {
            paragraphe
          }
          ... on Actualite_Bloccontenu_BlocsContenus_Image {
            image {
              altText
              sourceUrl
            }
          }
          ... on Actualite_Bloccontenu_BlocsContenus_GalerieImages {
            images {
              altText
              sourceUrl
            }
          }
          ... on Actualite_Bloccontenu_BlocsContenus_Video {
            idVideo
            miniatureVideo {
              altText
              sourceUrl
            }
          }
          ... on Actualite_Bloccontenu_BlocsContenus_Bouton {
            libelleBouton
            typeDeLien
            lienExterne
            lienInterne {
              ... on Page {
                slug
              }
            }
            fichier {
              mediaItemUrl
            }
            icone {
              altText
              sourceUrl(size: LARGE)
              srcSet
            }
          }
          ... on Actualite_Bloccontenu_BlocsContenus_Espaceur {
            taille
          }
        }
      }
      actualites {
        global {
          visuelActualite {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;
