import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { MetierDetailsWrapper } from "./styles/MetierDetailsStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_METIER_DETAILS_DATA } from "graphql/get_metier_details_page_data";
import { GlobalContext } from "GlobalContext";

import ContentBlock from "components/ContentBlock";
import NewsCarousel from "components/NewsCarousel";
import RecentPostsBlock from "components/RecentPostsBlock";

import heroBg from "images/home-hero-image.png";

const MetierDetails = (props) => {
  // Initial Values
  let contentBlocs = null;
  let newsList = null;
  let publicationsData = null;
  let intro = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzEw" : "cGFnZToyMTY=";
  const homePageId = currentLang === "en" ? "cGFnZTozNTA=" : "cGFnZTozNTA=";

  // GraphQl Queries
  const slug = props.match.params.slug;
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_METIER_DETAILS_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug, pageId, homePageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.metierBy.title,
        titre2: pageGqlData.hero_data.heroPage.sousTitre,
        image: {
          altText: pageGqlData.metierBy.visuelsMetier.visuelMetier.altText,
          sourceUrl: pageGqlData.metierBy.visuelsMetier.visuelMetier.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "Metier details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    contentBlocs = pageGqlData.metierBy.blocContenu.blocsContenus;
    newsList = pageGqlData.actualites.nodes;
    publicationsData = pageGqlData.page.homePage.publications;
    intro = pageGqlData.hero_data.heroPage.introduction;
    seo = pageGqlData.hero_data.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <MetierDetailsWrapper>
              {contentBlocs && (
                <div className="fixed-container">
                  {intro && (
                    <div className={`content-container white-bg no-title`}>
                      <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: intro }} />
                      </div>
                    </div>
                  )}

                  <div className="content-container white-bg no-title">
                    {contentBlocs &&
                      contentBlocs.map((block, i) => (
                        <ContentBlock key={i} block={block} />
                      ))}
                  </div>
                </div>
              )}

              {/* {newsList && <NewsCarousel newsList={newsList} />} */}

              {publicationsData && (
                <RecentPostsBlock publicationsData={publicationsData} />
              )}
            </MetierDetailsWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

MetierDetails.propTypes = {};

MetierDetails.defaultProps = {};

export default MetierDetails;
