import styled from "styled-components";

export const EventsWrapper = styled.div`
  .event-card {
    margin-bottom: 60px;
    cursor: pointer;
  }
  padding-bottom: 120px;
`;

export const EventCover = styled.div`
  margin-top: -86px;
  width: 100%;
  height: 580px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
