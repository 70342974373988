import styled from "styled-components";

export const NewsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    margin-bottom: 54px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 30px;
    margin-bottom: 20px;
  }
`;
