import React from "react";
import PropTypes from "prop-types";

import {
  SubMenuWrapper,
  SubMenuContainer,
  Menu,
  MenuItem,
  MenuItemLink,
  Author,
  SocialIcons,
} from "./styles/SubMenuStyles";

const SubMenu = ({ data, navigateTo }) => {
  const {
    adresse,
    libelleContact,
    lienContact,
    libelleMentionsLegales,
    lienMentionsLegales,
    copyright,
    socials,
  } = data;
  return (
    <React.Fragment>
      <SubMenuWrapper className="SubMenu">
        <SubMenuContainer>
          <Menu>
            <MenuItem>
              <span dangerouslySetInnerHTML={{ __html: adresse }}></span>
            </MenuItem>
            <MenuItem
              dangerouslySetInnerHTML={{
                __html: copyright.split("<p>").join("").split("</p>").join(""),
              }}
            />
            <MenuItem>
              <MenuItemLink
                onClick={() => navigateTo("/" + lienMentionsLegales.slug)}
              >
                {libelleMentionsLegales}
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => navigateTo("/" + lienContact.slug)}>
                {libelleContact}
              </MenuItemLink>
            </MenuItem>
          </Menu>
          <SocialIcons>
            {socials &&
              socials.map((item, i) => (
                <a
                  href={item.lien}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.icone.sourceUrl} alt={item.plateforme} />
                </a>
              ))}
          </SocialIcons>
          <Author>
            by{" "}
            <b>
              <a
                href="http://www.innoveos.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                innoveos
              </a>
            </b>
          </Author>
        </SubMenuContainer>
      </SubMenuWrapper>
    </React.Fragment>
  );
};

SubMenu.propTypes = {};

SubMenu.defaultProps = {};

export default SubMenu;
