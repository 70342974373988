import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useParams, useHistory } from "react-router-dom";

import {
  ContentWrapper,
  CategoriesWrapper,
  ListWrapper,
  MenuItem,
} from "./styles/PostsStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_POSTS_PAGE_DATA } from "graphql/get_posts_page_data";
import { GlobalContext } from "GlobalContext";

import PostCard from "components/PostCard";
import Paginator from "components/Paginator";

const Posts = (props) => {
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [postsList, setPostsList] = useState([]);
  const [postsCategories, setPostsCategories] = useState([]);

  let { category } = useParams();
  let history = useHistory();

  /////// Pagination
  const [offset, setOffset] = useState(0);
  const perPage = 4;

  // Initial Values
  let intro = null;
  let seo = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData, currentLang } =
    useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE1" : "cGFnZToyMjI=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_POSTS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("Posts page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  useEffect(() => {
    if (pageGqlData) {
      if (category) {
        let paramCat = pageGqlData.publicationCategories.nodes.filter(
          (categ) => categ.slug === category
        );

        if (paramCat[0]) setSelectedCategory(paramCat[0].name);
      }
      setPostsList(pageGqlData.publications.nodes);
      selectedCategory === 0 &&
        setFilteredPosts(pageGqlData.publications.nodes);
      let postsCatsTemp = [...postsCategories];
      pageGqlData.publications.nodes.length > 0 &&
        pageGqlData.publications.nodes.map((post, i) => {
          post.publicationCategories.nodes.map(
            (pc, i) =>
              !postsCatsTemp.includes(pc.name) && postsCatsTemp.push(pc.name)
          );
        });
      setPostsCategories(postsCatsTemp);
      intro = pageGqlData.page.heroPage.introduction;
      seo = pageGqlData.page.seo;
    }
  }, [pageGqlData]);

  useEffect(() => {
    setOffset(0);
    if (selectedCategory !== 0) {
      const filtered_posts = [...postsList].filter((postsItem) => {
        let postBelongsToCategory = false;
        postsItem.publicationCategories.nodes.map((pc, i) => {
          if (pc.name === selectedCategory) postBelongsToCategory = true;
        });
        return postBelongsToCategory;
      });
      setFilteredPosts(filtered_posts);
    } else {
      setFilteredPosts(postsList);
    }
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    let url = currentLang === "en" ? "/en/publications" : "/publications";
    if (category !== 0) {
      let selectedCat = pageGqlData.publicationCategories.nodes.filter(
        (categ) => categ.name === category
      );
      url += "/" + selectedCat[0].slug;
    }
    history.replace({ pathname: url });
    setSelectedCategory(category);
  };

  const handlePageClick = (e) => {
    const new_offset = e.selected * perPage;
    setOffset(new_offset);
    scrollToTop();
  };

  const scrollToTop = () => {
    const element = document.getElementById("listing");
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop - 40 : 0,
      });
    }, 100);
  };

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="fixed-container">
              {intro && (
                <div className={`content-container white-bg no-title`}>
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                  </div>
                </div>
              )}
              <div>
                <ContentWrapper>
                  {postsCategories && postsCategories.length !== 0 && (
                    <CategoriesWrapper>
                      <MenuItem
                        key={0}
                        className={selectedCategory === 0 && "active"}
                        onClick={() => handleCategoryClick(0)}
                      >
                        {currentLang === "en"
                          ? "All categories"
                          : "Toutes les catégories"}
                      </MenuItem>
                      {postsCategories.sort().map((category, i) => (
                        <MenuItem
                          onClick={() => handleCategoryClick(category)}
                          key={i}
                          className={selectedCategory === category && "active"}
                          dangerouslySetInnerHTML={{ __html: category }}
                        />
                      ))}
                    </CategoriesWrapper>
                  )}
                  {filteredPosts && (
                    <ListWrapper id="listing">
                      <div>
                        {filteredPosts
                          .slice(offset, offset + perPage)
                          .map((actualite, i) => (
                            <PostCard
                              key={actualite.id}
                              data={actualite}
                              showExcerpt
                            />
                          ))}
                      </div>
                      <Paginator
                        data={filteredPosts}
                        perPage={perPage}
                        handlePageClick={handlePageClick}
                      />
                    </ListWrapper>
                  )}
                </ContentWrapper>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [
    pageGqlData,
    filteredPosts,
    selectedCategory,
    offset,
    activeMegaMenu,
    isLoading,
  ]);
};

Posts.propTypes = {};

Posts.defaultProps = {};

export default Posts;
