import styled from "styled-components";

export const HistoryCarouselWrapper = styled.div`
  .carousel {
    &.carousel-slider .control-arrow {
      width: 12.1%;
      height: 12.1vw;
      max-height: 12.1vw;
      background: #0043ff;
      opacity: 1;
      background-image: url(${(props) => props.arrow});
      background-repeat: no-repeat;
      background-position: center;
      @media (max-width: 768px) {
        width: 9.1%;
        height: 9.1vw;
        max-height: 9.1vw;
        background-size: 30%;
        &.control-prev {
          margin-left: 80.4% !important;
        }
      }
      &.control-prev {
        margin-left: 75.8%;
        transform: rotate(180deg);
        background-color: rgba(0, 67, 255, 0.9);
        :before {
          display: none;
        }
      }
      &.control-next {
        :before {
          display: none;
        }
      }
    }
    li.slide {
      background: transparent !important;
      opacity: 0.4;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        height: auto
      }
      img {
        width: 96% !important;
        height: calc(100% - 76px) !important;
      }
      .image,
      .content {
        opacity: 0;
      }
      &.selected {
        opacity: 1;
        img {
          width: 100%;
          height: 100% !important;
        }
        .image,
        .content {
          opacity: 1;
        }
      }
    }
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 99%;
  min-height: 500px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    min-height: initial;
  }
`;

export const ImageConatiner = styled.div`
  min-width: 45%;
  min-height: 100%;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 55%;
  min-height: 100%;
  padding: 0 0 30px 0;
  background-color: #f1f5ff;
  text-align: left;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: ${(props) => (props.showFullDate ? "flex-end" : "center")};
  justify-content: center;
  flex-direction: column;
  color: #043dba;
  font-weight: 500;
  height: 12.1vw;
  padding-right: ${(props) => (props.showFullDate ? "36%" : "30%")};
  margin-bottom: 35px;
  background-color: #fff;
  @media (max-width: 768px) {
    background-color: transparent;
    height: auto;
    margin-top: 60px;
    margin-bottom: 20px;
  }
`;

export const DayMonth = styled.div`
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
    align-self: flex-start;
    padding-left: 15px;
  }
`;

export const Year = styled.div`
  font-size: 5rem;
  line-height: 70px;
  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.83rem;
    width: 100%;
    padding-left: 15px;
  }
`;

export const Intro = styled.div`
  font-size: 1.58rem;
  line-height: 1.91rem;
  color: #02203c;
  margin-bottom: 30px;
  padding: 0 30px;
  p {
    font-size: 1.58rem;
    line-height: 1.91rem;
    color: #02203c;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-bottom: 10px;
    padding: 0 15px;
    p{
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }
`;

export const Text = styled.div`
  font-size: 1.34rem;
  line-height: 1.83rem;
  color: #02203c;
  padding: 0 30px 0 60px;
  p {
    font-size: 1.34rem;
    line-height: 1.83rem;
    color: #02203c;
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    padding: 0 15px;
    p {
      line-height: 1.8rem;
      font-size: 1.2rem;
    }
  }
`;
