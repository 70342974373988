import React, { useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { useLocation } from "react-router-dom";

import { RemoveBaseUrl } from "utils";

import {
  MegaMenuWrapper,
  MenuContainer,
  NavigationWrapper,
  MenuItem,
  CoverWrapper,
} from "./styles/MegaMenuStyles";

import menuCover from "images/home-hero-image.png";

const MegaMenu = React.memo((props) => {
  const { megamenuData, navigateTo } = useContext(GlobalContext);
  const { visuelMegamenu, menuItems, loading, error } = megamenuData;
  useEffect(() => {
    error && console.log("Megamenu data loading error: ", error.message);
  }, [error]);

  let location = useLocation();

  return useMemo(() => {
    return (
      <React.Fragment>
        <MegaMenuWrapper className="megamenu">
          <MenuContainer>
            <NavigationWrapper>
              {menuItems &&
                menuItems.map((menu, i) => (
                  <MenuItem
                    onClick={() => navigateTo(RemoveBaseUrl(menu.url), true)}
                    key={i}
                    className={
                      location.pathname.startsWith(RemoveBaseUrl(menu.url)) ||
                      location.pathname === RemoveBaseUrl(menu.url)
                        ? "active"
                        : ""
                    }
                  >
                    {menu.label}
                  </MenuItem>
                ))}
            </NavigationWrapper>
            <CoverWrapper bg={visuelMegamenu || menuCover}></CoverWrapper>
          </MenuContainer>
        </MegaMenuWrapper>
      </React.Fragment>
    );
  }, [megamenuData]);
});

MegaMenu.propTypes = {
  menuItems: PropTypes.array,
};

MegaMenu.defaultProps = {
  menuItems: [],
};

export default MegaMenu;
