import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { getDay, getMonth, getYear } from "utils";

import {
  CareerOfferCardWrapper,
  DetailsContainer,
  DateContainer,
  DayMonth,
  Sep,
  Year,
  Content,
  Title,
} from "./styles/CareerOfferCardStyles";

const CareerOfferCard = ({ data, type }) => {
  const { navigateTo, currentLang } = useContext(GlobalContext);
  const { slug, title, date } = data;
  return (
    <React.Fragment>
      <CareerOfferCardWrapper
        type={type}
        className="career-offer-card"
        onClick={() => navigateTo("/carrieres/" + slug)}
      >
        <DetailsContainer>
          <DateContainer>
            <DayMonth>
              {getDay(date)}
              <Sep />
              {getMonth(date, currentLang === "en" ? "en" : "fr")}
            </DayMonth>
            <Year>{getYear(date)}</Year>
          </DateContainer>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </Content>
        </DetailsContainer>
      </CareerOfferCardWrapper>
    </React.Fragment>
  );
};

CareerOfferCard.propTypes = {};

CareerOfferCard.defaultProps = {};

export default CareerOfferCard;
