import styled from "styled-components";

export const TrainingModuleWrapper = styled.div`
  background-color: ${(props) =>
    props.odd ? "rgba(215, 226, 255, 0.3)" : "rgba(215,226,255,0.7)"};
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const Label = styled.div`
  color: #043dba;
  font-size: 1.82rem;
  line-height: 2.25rem;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    line-height: .5rem;
  }
`;

export const Title = styled.div`
  color: #043dba;
  font-size: 2.17rem;
  font-weight: 500;
  line-height: 2.58rem;
  padding-left: 20px;
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding: 2px 0;
    padding-left: 10px;
  }
`;

export const Content = styled.div`
  color: #02203C;
  font-size: 1.6rem;
  line-height: 2.33rem;
  padding-left: 60px;
  padding-right: 50px;
  margin: 30px 0;
  @media (max-width: 768px) {
    margin: 2px 0;
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
  }
}`;
