import React, { useState, createContext } from "react";
import { useHistory, useParams } from "react-router-dom";

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
  const { lang } = useParams();
  const langPrefix = lang && lang === "en" ? "/en" : "";
  // Navigation
  let history = useHistory();
  const navigateTo = (route, baseRemoved = false) => {
    const prefix = !baseRemoved ? langPrefix : "";
    if (window.pageYOffset !== 0) {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
      window.onscroll = (e) => {
        let currentScrollOffset =
          window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollOffset === 0) {
          history.push(prefix + route);
          window.onscroll = null; // remove listener
        }
      };
    } else {
      history.push(prefix + route);
    }
    setActiveMegaMenu(false);
  };

  // Global states
  const [isLoading, setIsLoading] = useState(false);
  const [activeMegaMenu, setActiveMegaMenu] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    langPrefix === "/en" ? "en" : "fr"
  );
  const [topbarData, setTopbarData] = useState({
    menuItems: [],
    loading: false,
    error: null,
  });
  const [navbarData, setNavbarData] = useState({
    logo: null,
    logoBleu: null,
    menuItems: [],
    loading: false,
    error: null,
  });
  const [megamenuData, setMegamenuData] = useState({
    menuItems: [],
    loading: false,
    error: null,
  });
  const [heroData, setHeroData] = useState({
    titre1: null,
    titre2: null,
    labelLectureVideo: null,
    idVideo: null,
    image: {
      altText: null,
      sourceUrl: null,
    },
    loading: false,
    error: null,
  });
  const [newsletterData, setNewsletterData] = useState({
    description: null,
    libelleBouton: null,
    placeholder: null,
    messageDeConfirmation: null,
    loading: false,
    error: null,
  });
  const [footerData, setFooterData] = useState({
    navmenu: {
      logo: null,
      menuItems: [],
      socials: [],
    },
    footerElements: null,
    loading: false,
    error: null,
  });
  return (
    <GlobalContext.Provider
      value={{
        currentLang,
        setCurrentLang,
        isLoading,
        setIsLoading,
        activeMegaMenu,
        setActiveMegaMenu,
        topbarData,
        setTopbarData,
        navbarData,
        setNavbarData,
        megamenuData,
        setMegamenuData,
        heroData,
        setHeroData,
        newsletterData,
        setNewsletterData,
        footerData,
        setFooterData,
        navigateTo,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
