import React, { useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import Newsletter from "./Newsletter";
import NavMenu from "./NavMenu";
import SubMenu from "./SubMenu";
import { FooterWrapper } from "./styles/FooterStyles";

const Footer = (props) => {
  const { footerData, navigateTo } = useContext(GlobalContext);
  const { navmenu, footerElements, loading, error } = footerData;
  if (navmenu && footerElements) footerElements.socials = navmenu.socials;
  useEffect(() => {
    error && console.log("Footer data loading error: ", error.message);
  }, [error]);
  return useMemo(() => {
    return (
      <React.Fragment>
        <FooterWrapper className="footer">
          <Newsletter />
          {navmenu && <NavMenu data={navmenu} navigateTo={navigateTo} />}
          {footerElements && (
            <SubMenu data={footerElements} navigateTo={navigateTo} />
          )}
        </FooterWrapper>
      </React.Fragment>
    );
  }, [footerData]);
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
