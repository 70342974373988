import { gql } from "apollo-boost";

export const GET_POSTS_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    publicationCategories {
      nodes {
        id
        name
        slug
        databaseId
      }
    }
    publications(first: 10000) {
      nodes {
        id
        title
        date
        slug
        publications {
          global {
            typePublication
            visuelPublication {
              altText
              sourceUrl
            }
          }
        }
        publicationCategories {
          nodes {
            id
            name
            slug
            databaseId
          }
        }
        blocContenu {
          blocsContenus {
            ... on Publication_Bloccontenu_BlocsContenus_Paragraphe {
              paragraphe
            }
          }
        }
      }
    }
  }
`;
