import React from "react";
import PropTypes from "prop-types";

import aboutBg from "images/amadeus-flag-bg.jpg";

import {
  GContainer,
  AboutBlockWrapper,
  ImageContainer,
  Image,
  InfosContainer,
  Title,
  Content,
  Slogan,
  Clear,
} from "./styles/AboutBlockStyles";

const AboutBlock = ({ aboutData }) => {
  const { titre, contenu, signature, visuel } = aboutData;
  return (
    <React.Fragment>
      <GContainer>
        <AboutBlockWrapper className="about-block">
          <ImageContainer>
            {visuel && <Image src={visuel.sourceUrl} alt={visuel.altText} />}
          </ImageContainer>
          <InfosContainer>
            <Title dangerouslySetInnerHTML={{ __html: titre }} />
            <Content dangerouslySetInnerHTML={{ __html: contenu }} />
            <Slogan>
              <span dangerouslySetInnerHTML={{ __html: signature }}></span>
            </Slogan>
          </InfosContainer>
        </AboutBlockWrapper>
        <Clear />
      </GContainer>
    </React.Fragment>
  );
};

AboutBlock.propTypes = {};

AboutBlock.defaultProps = {};

export default AboutBlock;
