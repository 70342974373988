import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
  AuthorBlockWrapper,
  ImageContainer,
  InfosContainer,
  Name,
  Bio,
} from "./styles/AuthorBlockStyles";

const AuthorBlock = ({ data }) => {
  const { biographie, prenomNom, photo } = data;
  return (
    <React.Fragment>
      <AuthorBlockWrapper className="author-block">
        <ImageContainer>
          {photo && <img src={photo.sourceUrl} alt={photo.altText} />}
        </ImageContainer>
        <InfosContainer>
          <Name>PAR : {prenomNom}</Name>
          <Bio dangerouslySetInnerHTML={{ __html: biographie }} />
        </InfosContainer>
      </AuthorBlockWrapper>
    </React.Fragment>
  );
};

AuthorBlock.propTypes = {};

AuthorBlock.defaultProps = {};

export default AuthorBlock;
