import styled from "styled-components";

export const NewsSummaryCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  background-color: #fafbff;
  padding: 32px 30px;
`;

export const DetailsContainer = styled.div`
  display: flex;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: #0043ff;
  font-size: 1.82rem;
  font-weight: 300;
  text-align: right;
  min-width: 170px;
  @media (max-width: 768px) {
    font-size: 1rem;
    min-width: 90px;
  }
`;

export const DayMonth = styled.span`
  white-space: nowrap;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
  text-transform: capitalize;
  @media (max-width: 768px) {
    margin-bottom: 2px;
  }
`;

export const Sep = styled.div`
  width: 30px;
  height: 18px;
  margin: 0 2px;
  border-bottom: 1px solid;
  color: #0043ff;
  @media (max-width: 768px) {
    height: 12px;
  }
`;

export const Year = styled.span``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  font-size: 1.82rem;
  line-height: 2.25rem;
  @media (max-width: 768px) {
    padding-left: 15px;
  }
`;

export const Title = styled.div`
  color: #092063;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: justify;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.33rem;
    text-align: initial;
  }
`;
