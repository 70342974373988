import { gql } from "apollo-boost";

export const GET_NEWS_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    categories {
      nodes {
        id
        name
        slug
        actualites {
          nodes {
            title
          }
        }
      }
    }
    actualites(first: 1000) {
      nodes {
        id
        title
        date
        slug
        actualites {
          global {
            miseEnAvantHome
            visuelActualite {
              altText
              sourceUrl
            }
          }
        }
        categories {
          nodes {
            name
            slug
            id
          }
        }
        blocContenu {
          blocsContenus {
            ... on Actualite_Bloccontenu_BlocsContenus_Paragraphe {
              paragraphe
            }
          }
        }
      }
    }
  }
`;
