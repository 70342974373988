import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  TrainingPackagedWrapper,
  TrainigsList,
} from "./styles/TrainingPackagedStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_FORMATIONS_PACKAGEES_PAGE_DATA } from "graphql/get_formations_packagees_page_data";
import { GlobalContext } from "GlobalContext";

import TrainingCard from "components/TrainingCard";

const TrainingPackaged = (props) => {
  // Initial Values
  let intro = null;
  let trainingsListData = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE4" : "cGFnZToyMzI=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_FORMATIONS_PACKAGEES_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "'Formations packagées' page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    intro = pageGqlData.page.heroPage.introduction;
    trainingsListData = pageGqlData.formations_packagee
      ? pageGqlData.formations_packagee.nodes
      : null;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <TrainingPackagedWrapper>
              <div className="fixed-container">
                {intro && (
                  <div className={`content-container white-bg no-title`}>
                    <div className="container">
                      <div dangerouslySetInnerHTML={{ __html: intro }} />
                    </div>
                  </div>
                )}

                {trainingsListData && (
                  <TrainigsList>
                    {trainingsListData.map((training, i) => (
                      <TrainingCard data={training} key={i} odd />
                    ))}
                  </TrainigsList>
                )}
              </div>
            </TrainingPackagedWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

TrainingPackaged.propTypes = {};

TrainingPackaged.defaultProps = {};

export default TrainingPackaged;
