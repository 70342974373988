import { gql } from "apollo-boost";

export const GET_FOOTER_DATA = gql`
  query($footerMenuId: ID!) {
    footer_menu: menu(id: $footerMenuId) {
      menuItems {
        nodes {
          label
          id
          url
        }
      }
    }
    paramTresSite {
      parametres {
        elementsGraphiques {
          logoIaBlanc {
            altText
            sourceUrl
          }
        }
        reseauxSociaux {
          profilRs {
            plateforme
            lien
            icone {
              altText
              sourceUrl
            }
          }
        }
        elementsFooter {
          adresse
          libelleContact
          libelleMentionsLegales
          copyright
          lienMentionsLegales {
            ... on Page {
              slug
            }
          }
          lienContact {
            ... on Page {
              slug
            }
          }
        }
      }
    }
  }
`;
