import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Route, Switch, useParams } from "react-router-dom";
import routes from "routes";
import { GlobalContext } from "GlobalContext";
import { useQuery } from "@apollo/react-hooks";
import { GET_TOPBAR_DATA } from "graphql/get_topbar_data";
import { GET_NAVBAR_DATA } from "graphql/get_navbar_data";
import { GET_MEGAMENU_DATA } from "graphql/get_megamenu_data";
import { GET_NEWSLETTER_DATA } from "graphql/get_newsletter_data";
import { GET_FOOTER_DATA } from "graphql/get_footer_data";

import Header from "components/Header";
import Footer from "components/Footer";
import NotFound from "pages/NotFound";

import loader from "images/grid-menu-loader.svg";

const Pages = (props) => {
  const { lang } = useParams();
  const langPrefix = lang && lang === "en" ? "/en" : "";

  // Consuming Context
  const {
    currentLang,
    isLoading,
    navbarData,
    setNavbarData,
    topbarData,
    setTopbarData,
    activeMegaMenu,
    megamenuData,
    setMegamenuData,
    newsletterData,
    setNewsletterData,
    footerData,
    setFooterData,
  } = useContext(GlobalContext);

  const langMenuId = currentLang === "en" ? "TWVudToxNA==" : "TWVudTo2";
  const mainMenuId = currentLang === "en" ? "TWVudToxNw==" : "TWVudToy";
  const footerMenuId = currentLang === "en" ? "TWVudToxNQ==" : "TWVudToz";
  const megaMenuId = currentLang === "en" ? "TWVudToxNg==" : "TWVudTo1";

  // GraphQl Queries
  const {
    loading: topbarGqlLoading,
    error: topbarGqlError,
    data: topbarGqlData,
  } = useQuery(GET_TOPBAR_DATA, {
    fetchPolicy: "no-cache",
    variables: { langMenuId },
  });

  const {
    loading: navbarGqlLoading,
    error: navbarGqlError,
    data: navbarGqlData,
  } = useQuery(GET_NAVBAR_DATA, {
    fetchPolicy: "no-cache",
    variables: { mainMenuId, footerMenuId },
  });

  const {
    loading: megamenuGqlLoading,
    error: megamenuGqlError,
    data: megamenuGqlData,
  } = useQuery(GET_MEGAMENU_DATA, {
    fetchPolicy: "no-cache",
    variables: { megaMenuId },
  });

  const {
    loading: newsletterGqlLoading,
    error: newsletterGqlError,
    data: newsletterGqlData,
  } = useQuery(GET_NEWSLETTER_DATA, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: footerGqlLoading,
    error: footerGqlError,
    data: footerGqlData,
  } = useQuery(GET_FOOTER_DATA, {
    fetchPolicy: "no-cache",
    variables: { footerMenuId },
  });

  // Setting Data
  useEffect(() => {
    topbarGqlData
      ? setTopbarData({
          ...topbarData,
          menuItems: topbarGqlData.langues_menu.menuItems.nodes,
        })
      : setTopbarData({
          ...topbarData,
          loading: topbarGqlLoading,
          error: topbarGqlError,
        });
  }, [topbarGqlLoading]);

  useEffect(() => {
    navbarGqlData
      ? setNavbarData({
          ...navbarData,
          logo:
            navbarGqlData.paramTresSite.parametres.elementsGraphiques
              .logoIaBlanc.sourceUrl,
          logoBleu:
            navbarGqlData.paramTresSite.parametres.elementsGraphiques.logoIaBleu
              .sourceUrl,
          menuItems: navbarGqlData.main_menu.menuItems.nodes,
        })
      : setNavbarData({
          ...navbarData,
          loading: navbarGqlLoading,
          error: navbarGqlError,
        });
  }, [navbarGqlLoading]);

  useEffect(() => {
    megamenuGqlData
      ? setMegamenuData({
          ...megamenuData,
          menuItems: megamenuGqlData.mega_menu.menuItems.nodes,
          visuelMegamenu:
            megamenuGqlData.paramTresSite.parametres.elementsGraphiques &&
            megamenuGqlData.paramTresSite.parametres.elementsGraphiques
              .visuelMegamenu &&
            megamenuGqlData.paramTresSite.parametres.elementsGraphiques
              .visuelMegamenu.sourceUrl
              ? megamenuGqlData.paramTresSite.parametres.elementsGraphiques
                  .visuelMegamenu.sourceUrl
              : null,
        })
      : setMegamenuData({
          ...megamenuData,
          loading: megamenuGqlLoading,
          error: megamenuGqlError,
        });
  }, [megamenuGqlLoading]);

  useEffect(() => {
    newsletterGqlData
      ? setNewsletterData({
          ...newsletterData,
          ...newsletterGqlData.paramTresSite.parametres.newsletter,
        })
      : setNewsletterData({
          ...newsletterData,
          loading: newsletterGqlLoading,
          error: newsletterGqlError,
        });
  }, [newsletterGqlLoading]);

  useEffect(() => {
    footerGqlData
      ? setFooterData({
          ...footerData,
          navmenu: {
            logo:
              footerGqlData.paramTresSite.parametres.elementsGraphiques
                .logoIaBlanc.sourceUrl,
            menuItems: footerGqlData.footer_menu.menuItems.nodes,
            socials:
              footerGqlData.paramTresSite.parametres.reseauxSociaux.profilRs,
          },
          footerElements: footerGqlData.paramTresSite.parametres.elementsFooter,
        })
      : setFooterData({
          ...footerData,
          loading: footerGqlLoading,
          error: footerGqlError,
        });
  }, [footerGqlLoading]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header />
          {isLoading && (
            <div
              className="fixed-container"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "125px",
              }}
            >
              {/* <img src={loader} alt="Chargement ..." /> */}
            </div>
          )}
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} path={langPrefix + route.path} />
            ))}
            <Route component={NotFound} />
          </Switch>
          {!activeMegaMenu && <Footer />}
        </div>
      </React.Fragment>
    );
  }, [isLoading, activeMegaMenu]);
};

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;
