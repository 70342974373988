import { gql } from "apollo-boost";

export const GET_HOME_PAGE_DATA = gql`
  {
    page(id: "cGFnZTozNTA=") {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      homePage {
        hero {
          titre1
          titre2
          labelLectureVideo
          idVideo
          miniatureVideo {
            altText
            sourceUrl
          }
        }
        actualites {
          titreRubrique
          labelToutesLesActualites
          lienToutesLesActualites
        }
        institutAmadeus {
          titre
          contenu
          signature
          visuel {
            altText
            sourceUrl
          }
        }
        evenements {
          titreRubrique
          evenement {
            evenement {
              ... on Evenement {
                id
                slug
                informationsEvenement {
                  dateDebut
                  dateFin
                  lieu
                  theme
                  titre
                  type
                  visuelEvenement {
                    altText
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        metiers {
          titreRubrique
          metier {
            metier {
              ... on Metier {
                id
                title
                slug
                visuelsMetier {
                  iconesMetier {
                    iconeSurvol {
                      altText
                      sourceUrl
                    }
                    iconeNormale {
                      altText
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
        formations {
          titreRubrique
          description
          imageArrierePlan {
            altText
            sourceUrl
          }
          labelToutesLesFormations
          lienToutesLesFormations
          formations {
            formation {
              ... on Formation_packagee {
                id
                title
                slug
                formationsPackagees {
                  sousTitre
                }
              }
            }
          }
        }
        publications {
          titreRubrique
          labelToutesLesPublications
          lienToutesLesPublications
          publications {
            lienPublication {
              ... on Publication {
                id
                slug
                publications {
                  global {
                    couverturePublication {
                      altText
                      sourceUrl
                    }
                  }
                }
                publicationCategories {
                  nodes {
                    id
                    name
                    slug
                    databaseId
                  }
                }
              }
            }
          }
        }
      }
    }
    actualites {
      nodes {
        id
        title
        date
        slug
        blocContenu {
          blocsContenus {
            ... on Actualite_Bloccontenu_BlocsContenus_Paragraphe {
              paragraphe
            }
          }
        }
        actualites {
          global {
            miseEnAvantHome
            visuelActualite {
              altText
              sourceUrl
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    publications {
      nodes {
        id
        title
        date
        slug
        publications {
          global {
            miseEnAvantHome
            typePublication
            visuelPublication {
              altText
              sourceUrl
            }
          }
        }
        publicationCategories {
          nodes {
            id
            name
            slug
            databaseId
          }
        }
      }
    }
  }
`;
