import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: #00081e;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
  margin-top: 200px;
  @media (max-width: 768px) {
    margin-top: 110px;
    padding-bottom: 18px;
  }
`;
