import styled from "styled-components";

export const TrainingObjectivesWrapper = styled.div`
  display: flex;
  font-weight: 300;
  overflow: visible;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 600px;
  @media (max-width: 768px) {
    min-width: auto;
  }
  ol {
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      margin-bottom: 22px;
      counter-increment: li;
      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
      :before {
        content: "." counter(li);
        color: #0043ff;
        display: block;
        margin-right: 10px;
        text-align: right;
        direction: rtl;
        width: 20px;
        min-width: 20px;
        font-weight: 400;
      }
    }
  }
`;

export const ObjectivesList = styled.div`
  font-size: 1.91rem;
  line-height: 2.66rem;
  padding-right: 20px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 15px;
  }
`;

export const TrainingDuration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0043ff;
  color: #fafbff;
  padding: 34px 0;
  @media (max-width: 768px) {
    padding: 16px 0;
  }
`;

export const Label = styled.span`
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 2px;
  }
`;

export const Duration = styled.span`
  font-size: 3.33rem;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const RightBlock = styled.div`
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  background-color: #1f1f98;
  font-size: 1.91rem;
  line-height: 2.66rem;
  padding: 45px 35px;
  color: #fafbff;
  padding-right: 20px;
  @media (max-width: 768px) {
    min-width: auto;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 25px 15px;
  }
  p, ul, li, a {
    color: #fff;
  }
  div {
    width: 70%;
  }
  ul {
    max-width: 440px;
    margin: 0;
    padding-left: 52px;
    li {
      margin-bottom: 22px;
    }
    @media (max-width: 768px) {
      max-width: initial;
      padding-left: 15px;
      li {
        margin-bottom: 12px;
      }
    }
  }
`;

export const Title = styled.span`
  max-width: 440px;
  color: #02c5ff;
  font-size: 3.75rem;
  margin-bottom: 60px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    max-width: initial;
    font-size: 1.75rem;
    margin-bottom: 20px;
  }
`;
