import React from "react";
import PropTypes from "prop-types";
import closeBtnSVG from "images/play.svg";

import { VideoPlayerWrapper, CloseBtn } from "./styles/VideoPlayerStyles";

const VideoPlayer = ({ videoId, setShowVideo }) => {
  return (
    <React.Fragment>
      <VideoPlayerWrapper className="video-player">
        <iframe
          title="youtube"
          width="100%"
          frameBorder="0"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&listType=playlist`}
        ></iframe>
        <CloseBtn onClick={() => setShowVideo(false)} />
      </VideoPlayerWrapper>
    </React.Fragment>
  );
};

VideoPlayer.propTypes = {};

VideoPlayer.defaultProps = {};

export default VideoPlayer;
