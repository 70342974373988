import styled from "styled-components";

export const LogoContainer = styled.a`
  display: flex;
  cursor: pointer;
  padding-left: 10%;
  padding-top: 25px;
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-left: 5.5%;
  }
`;

export const Logo = styled.img`
  height: 80px;
  @media (max-width: 768px) {
    height: 32px;
  }
`;

export const NavMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  transition: 0.3s;
`;

export const NavMenu = styled.div`
  padding: 5px;
  display: flex;
  padding-top: 68px;
  position: relative;
  transition: 0.3s;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  height: fit-content;
  min-height: 25px;
`;

export const MenuItem = styled.a`
  padding: 0 18px;
  color: #fafbff;
  font-weight: 500;
  font-size: 1.34rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 25px;
  text-decoration: none;
  transition: 0.3s;
`;

export const SubMenu = styled.div`
  padding: 5px 25px 5px 0;
  max-width: 20px;
  text-transform: initial;
  overflow: visible;
  display: none;
  &.active {
    display: block;
  }
  &.last {
    display: flex;
    align-self: flex-end;
    direction: rtl;
  }
  ul {
    z-index: 6;
    list-style: none;
    padding-left: 30px;
    padding-right: 0;
  }
`;

export const SubMenuItem = styled.a`
  color: #0043ff;
  font-size: 1.24rem;
  line-height: 1.6rem;
  padding: 8px 0;
  cursor: pointer;
  display: inline-block;
  width: 220px;
  &.active,
  :hover {
    font-weight: 500;
  }
`;

export const MenuBtn = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 55px;
  background: #fafbff;
  z-index: 999;
  position: relative;
  img {
    height: 40px;
  }
  @media (max-width: 768px) {
    padding: 16px;
    img {
      height: 20px;
    }
  }
`;

export const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &.hovered {
    ${NavMenuContainer} {
      background: #fff;
      height: fit-content;
      min-height: 150px;
    }
    ${NavMenu} {
      :before {
        content: "";
        display: block;
        background: #d7e2ff;
        height: 3px;
        width: calc(100% - 44px);
        position: absolute;
        left: 22px;
        top: 90px;
      }
    }
    ${MenuItem} {
      color: #0043ff;
      :hover:after,
      &.active:after {
        content: "";
        display: block;
        background: #0043ff;
        height: 3px;
        width: calc(100% - 34px);
        position: absolute;
        left: 17px;
        top: 22px;
      }
    }
  }
  max-height: 150px;
  overflow: visible;
`;
