import styled from "styled-components";

export const Intro = styled.div`
  margin-bottom: 90px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const EventsWrapper = styled.div`
  .event-card {
    margin-bottom: 60px;
    cursor: pointer;
  }
  padding-bottom: 120px;
  @media (max-width: 768px) {
    .event-card {
      margin-bottom: 20px;
    }
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 40px;
  }
`;

export const EventCover = styled.div`
  width: 100%;
  height: 580px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 250px;
  }
`;

export const SliderSectionTitle = styled.div`
  color: #fafbff;
  font-size: 3.75rem;
  line-height: 4.41rem;
  text-align: center;
  padding: 30px 0;
  background-color: #043dba;
  text-transform: uppercase;
  position: relative;
  :before {
    content: "";
    width: calc(50% - 230px);
    left: 0;
    height: 2px;
    border: 2px solid #fafbff;
    position: absolute;
    bottom: 53px;
  }
  :after {
    content: "";
    width: calc(50% - 230px);
    right: 0;
    height: 2px;
    border: 2px solid #fafbff;
    position: absolute;
    bottom: 53px;
  }
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.41rem;
    padding: 20px 0;
    :before, :after {
      height: 1px;
      width: calc(50% - 112px);
      border: 1px solid #fafbff;
      bottom: 28px;
    }
  }
`;
