import styled from "styled-components";

export const TopbarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: #00103c;
  padding-right: 40px;
  @media (max-width: 768px) {
    padding-right: 20px;
    height: 30px;
  }
`;

export const LangItemLink = styled.a`
  color: #fafbff;
  font-size: 1.24rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;
  border-right: 2px solid #fff;
  :last-child {
    padding-right: 0;
    border-right: none;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 10px;
  }
`;
