import styled from "styled-components";

export const NewsCarouselWrapper = styled.div`
  .rec-carousel {
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 60px;
  }
  .rec-slider-container {
    width: 1170px;
    overflow: visible;
    margin: 0;
  }
  .rec-item-wrapper {
    height: 100%;
    display: flex;
  }
  .news-card {
    max-width: calc(100% - 30px);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  line-height: 4.41rem;
`;

export const NextArrow = styled.img`
  cursor: pointer;
`;

export const MoreLinkWrapper = styled.div``;
