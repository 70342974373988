import styled from "styled-components";

export const TrainingBespokeWrapper = styled.div`
  .formations-block {
    margin-top: 200px;
  }
`;

export const CollapsibleList = styled.div`
  .collapsible {
    margin-top: 60px;
    @media (max-width: 768px) {
      margin-top: 15px;
      &:first-child {
        margin-top: 30px;
      }
    }
  }
`;
