import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useParams, useHistory } from "react-router-dom";

import {
  ContentWrapper,
  CategoriesWrapper,
  ListWrapper,
  MenuItem,
} from "./styles/NewsStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_NEWS_PAGE_DATA } from "graphql/get_news_page_data";
import { GlobalContext } from "GlobalContext";

import NewsCard from "components/NewsCard";
import NewsSummaryCard from "components/NewsSummaryCard";
import Paginator from "components/Paginator";

const News = (props) => {
  const [filteredNews, setFilteredNews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);

  let { category } = useParams();
  let history = useHistory();

  /////// Pagination
  const [offset, setOffset] = useState(0);
  const perPage = 10;

  // Initial Values
  let intro = null;
  let seo = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData, currentLang } =
    useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE5" : "cGFnZToyNDI=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_NEWS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("News page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  useEffect(() => {
    if (pageGqlData) {
      if (category) {
        let paramCat = pageGqlData.categories.nodes.filter(
          (categ) => categ.slug === category
        );

        if (paramCat[0]) setSelectedCategory(paramCat[0].id);
      }
      setNewsList(pageGqlData.actualites.nodes);
      selectedCategory === 0 && setFilteredNews(pageGqlData.actualites.nodes);
      setNewsCategories(
        pageGqlData.categories.nodes.filter(
          (category) => category.actualites.nodes.length > 0
        )
      );
      intro = pageGqlData.page.heroPage.introduction;
      seo = pageGqlData.page.seo;
    }
  }, [pageGqlData]);

  useEffect(() => {
    setOffset(0);
    if (selectedCategory !== 0) {
      const filtered_news = [...newsList].filter((newsItem) => {
        const filtered_categories = [...newsItem.categories.nodes].filter(
          (category) => category.id === selectedCategory
        );
        return filtered_categories.length !== 0;
      });
      setFilteredNews(filtered_news);
    } else {
      setFilteredNews(newsList);
    }
  }, [selectedCategory, newsList]);

  const handleCategoryClick = (idCategory) => {
    let url = currentLang === "en" ? "/en/news" : "/actualite";
    if (idCategory !== 0) {
      let selectedCat = pageGqlData.categories.nodes.filter(
        (category) => category.id === idCategory
      );
      url += "/" + selectedCat[0].slug;
    }
    history.replace({ pathname: url });
    setSelectedCategory(idCategory);
  };

  const handlePageClick = (e) => {
    const new_offset = e.selected * perPage;
    setOffset(new_offset);
    scrollToTop();
  };

  const scrollToTop = () => {
    const element = document.getElementById("listing");
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop - 40 : 0,
      });
    }, 100);
  };

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="fixed-container">
              {intro && (
                <div className={`content-container white-bg no-title`}>
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                  </div>
                </div>
              )}
              <div>
                <ContentWrapper>
                  {newsCategories && (
                    <CategoriesWrapper>
                      <MenuItem
                        key={0}
                        className={selectedCategory === 0 && "active"}
                        onClick={() => handleCategoryClick(0)}
                      >
                        {currentLang === "en"
                          ? "All categories"
                          : "Toutes les catégories"}
                      </MenuItem>
                      {newsCategories
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((category, i) => (
                          <MenuItem
                            onClick={() => handleCategoryClick(category.id)}
                            key={category.id}
                            className={
                              selectedCategory === category.id && "active"
                            }
                            dangerouslySetInnerHTML={{ __html: category.name }}
                          />
                        ))}
                    </CategoriesWrapper>
                  )}
                  {filteredNews && (
                    <ListWrapper id="listing">
                      <div>
                        {filteredNews
                          .slice(offset, offset + perPage)
                          .map((actualite, i) =>
                            i < 3 && offset === 0 ? (
                              <NewsCard
                                key={actualite.id}
                                data={actualite}
                                showExcerpt
                              />
                            ) : (
                              <NewsSummaryCard
                                key={actualite.id}
                                data={actualite}
                              />
                            )
                          )}
                      </div>
                      <Paginator
                        data={filteredNews}
                        perPage={perPage}
                        handlePageClick={handlePageClick}
                      />
                    </ListWrapper>
                  )}
                </ContentWrapper>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [
    pageGqlData,
    filteredNews,
    selectedCategory,
    offset,
    activeMegaMenu,
    isLoading,
  ]);
};

News.propTypes = {};

News.defaultProps = {};

export default News;
