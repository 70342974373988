import styled from "styled-components";

export const PressCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 160px;
  height: 270px;
  max-height: 270px;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: 768px) {
    height: initial;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #d7e2ff;
  padding: 18px 12px;
  width: 88%;
  flex: 1;
  margin-top: -55px;
  @media (max-width: 768px) {
    padding: 12px;
    margin-top: -33px;
  }
`;

export const Title = styled.div`
  color: #043dba;
  font-size: 1.34rem;
  line-height: 1.58rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  :after {
    margin-top: 25px;
    display: block;
    content: "";
    width: 30px;
    height: 3px;
    background: #043dba;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
    :after {
      margin-top: 8px;
      height: 1px;
    }
  }
`;

export const Date = styled.div`
  padding-top: 20px;
  color: #043dba;
  font-size: 1.2rem;
  line-height: 1.41rem;
  text-align: center;
  @media (max-width: 768px) {
    padding-top: 10px;
    font-size: .9rem;
    line-height: 0.83rem;
  }
`;
