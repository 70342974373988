import styled from "styled-components";

export const NewsletterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0043ff;
  padding: 40px;
  width: 52%;
  min-width: 700px;
  margin: auto;
  margin-top: -69px;
  margin-bottom: 75px;
  min-height: 140px;
  @media (max-width: 768px) {
    min-height: 100px;
    margin-top: -50px;
    width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    padding: 12px 20px 25px 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const Label = styled.span`
  color: #fafbff;
  font-size: 2.17rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.09rem;
    margin-bottom: 9px;
  }
`;

export const Form = styled.form`
  min-width: 356px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  color: #fafbff;
  font-size: 1.34rem;
  border: none;
  border-bottom: 1px solid #fafbff;
  background: transparent;
  padding: 8px 0;
  width: 300px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #fafbff;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #fafbff;
  }
  ::-ms-input-placeholder {
    color: #fafbff;
  }
  @media (max-width: 768px) {
    width: calc(100% - 52px);
    font-size: 1.1rem;
  }
`;

export const Button = styled.button`
  padding: 8px 0;
  color: #fafbff;
  font-size: 1.34rem;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #fafbff;
  background: transparent;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 52px;
    font-size: 1.1rem;
  }
`;

export const SubscribeMsg = styled.div`
  background: #fff;
  margin-top: 5px;
  padding: 2px 5px;
  border-radius: 2px;
  span {
    color: green;
    font-size: 1.1rem;
  }
  span.error {
    color: #ff0000;
    font-size: 1.1rem;
  }
`;
