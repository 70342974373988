import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import Link from "ui/Link";

import {
  RecentPostsBlockWrapper,
  Title,
  PostsGrid,
  Poster,
} from "./styles/RecentPostsBlockStyles";

const RecentPostsBlock = ({ publicationsData }) => {
  const { navigateTo } = useContext(GlobalContext);
  const {
    titreRubrique,
    publications,
    labelToutesLesPublications,
    lienToutesLesPublications
  } = publicationsData;
  return (
    <React.Fragment>
      <RecentPostsBlockWrapper className="posts-block">
        <div className="fixed-container">
          <Title
            className="withline"
            dangerouslySetInnerHTML={{ __html: titreRubrique }}
          />
          <PostsGrid>
            {publications &&
              publications.map(
                (publication, i) =>
                  publication.lienPublication && (
                    <Poster
                      key={i}
                      src={
                        publication.lienPublication.publications.global
                          .couverturePublication.sourceUrl
                      }
                      alt={
                        publication.lienPublication.publications.global
                          .couverturePublication.altText
                      }
                      onClick={() => {
                        let category = publication.lienPublication.publicationCategories ? publication.lienPublication.publicationCategories.nodes[0].slug : "uncategorized";
                        navigateTo(
                          "/publications/" + category + "/" + publication.lienPublication.slug
                        )
                      }}
                    />
                  )
              )}
          </PostsGrid>
          <Link
            label={labelToutesLesPublications}
            onClick={() => navigateTo(lienToutesLesPublications)}
          />
        </div>
      </RecentPostsBlockWrapper>
    </React.Fragment>
  );
};

RecentPostsBlock.propTypes = {};

RecentPostsBlock.defaultProps = {};

export default RecentPostsBlock;
