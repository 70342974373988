import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 60px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Block = styled.div`
  margin-bottom: 90px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 0px;
  line-height: 4.41rem;
  p {
    line-height: 4.41rem;
  }
  @media (max-width: 768px) {
    line-height: 1.83rem;
    margin-bottom: 8px;
    p {
      line-height: 1.8rem;
    }
  }
`;

export const ContactCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  padding: 50px 35px 30px 35px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
    grid-column-gap: 0;
    grid-row-gap: 10px;
    padding: 0;
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  background: #ebf0ff;
  color: #02203c;
  font-size: 1.91rem;
  line-height: 3.33rem;
  @media (max-width: 768px) {
    padding: 15px;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .link {
    margin-top: 10px;
    a span {
      font-size: 1.91rem;
      font-weight: 500;
      line-height: 2.33rem;
      @media (max-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.66rem;
      }
    }
  }
`;

export const Phone = styled.div`
  text-transform: uppercase;
`;

export const Fax = styled.div`
  text-transform: uppercase;
`;

export const Email = styled.div`
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const ContactInfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 85px;
  padding-top: 30px;
  font-size: 1.91rem;
  span {
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 0;
    font-size: 1.1rem;
    span {
      margin-bottom: 5px;
    }
  }
`;
