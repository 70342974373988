import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import NewsCard from "components/NewsCard";
import Link from "ui/Link";
import moment from "moment";

import { NewsBlockWrapper, Title, NewsGrid } from "./styles/NewsBlockStyles";

const NewsBlock = ({ newsData }) => {
  const { navigateTo } = useContext(GlobalContext);
  const { title, moreLinkLabel, moreLink, news } = newsData;
  const latestNews = news
    ? news
        .sort(
          (a, b) =>
            moment(b.date).format("YYYYMMDD") -
            moment(a.date).format("YYYYMMDD")
        )
        .filter((a) => a.actualites.global.miseEnAvantHome === "oui")
        .slice(0, 4)
    : null;
  return (
    <React.Fragment>
      <NewsBlockWrapper className="news-block">
        <div className="fixed-container">
          <Title>{title}</Title>
          <NewsGrid>
            {latestNews &&
              latestNews.map((actualite) => (
                <NewsCard key={actualite.id} data={actualite} />
              ))}
          </NewsGrid>
          <Link label={moreLinkLabel} onClick={() => navigateTo(moreLink)} />
        </div>
      </NewsBlockWrapper>
    </React.Fragment>
  );
};

NewsBlock.propTypes = {};

NewsBlock.defaultProps = {};

export default NewsBlock;
