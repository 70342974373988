import styled from "styled-components";

export const HeaderWrapper = styled.div`
  overflow: visible;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    background-size: 200% auto;
  }
`;
