import styled from "styled-components";

export const EventProgramWrapper = styled.div`
  background-color: ${(props) =>
    props.odd ? "rgba(215, 226, 255, 0.3)" : "rgba(215,226,255,0.7)"};
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const Time = styled.div`
  color: #043dba;
  font-size: 1.82rem;
  line-height: 2.25rem;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    margin-bottom: 4px;
  }
`;

export const Title = styled.div`
  color: #043dba;
  font-size: 2.17rem;
  font-weight: 500;
  line-height: 2.58rem;
  padding-left: 20px;
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding-left: 10px;
  }
`;

export const Content = styled.div`
  color: #02203C;
  font-size: 1.6rem;
  line-height: 2.33rem;
  padding-left: 60px;
  padding-right: 50px;
  margin: 30px 0;
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
    margin: 4px 0 0 0;
    p {
      margin-bottom: 8px
    }
  }
}`;

export const ModeratorsContainer = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  padding: 30px 90px;
  color: #02203c;
  font-size: 1.58rem;
  font-weight: 300;
  line-height: 1.91rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
`;

export const ModeratorName = styled.div`
  font-weight: 500;
`;

export const ModeratorProfession = styled.div`
  margin-left: 10px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SpeakersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  color: #02203c;
  font-size: 1.58rem;
  font-weight: 300;
  line-height: 1.91rem;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 15px;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
`;

export const SpeakersSectionTitle = styled.h2`
  color: #043dba;
  font-size: 2.5rem;
  line-height: 3rem;
  :before {
    content: "";
    width: 1002px;
    left: -1020px;
    height: 1px;
    border: 1px solid #043dba;
    position: absolute;
    bottom: 16px;
  }
  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 1.16rem;
    margin-bottom: 4px;
    :before {
      left: -1008px;
      bottom: 6px;
    }
  }
`;

export const SpeakerInfos = styled.div`
  margin-top: 25px;
  padding-left: 20px;
  @media (max-width: 768px) {
    margin-top: 6px;
    padding-left: 0;
  }
`;

export const SpeakerName = styled.div`
  font-weight: 500;
`;

export const SpeakerProfession = styled.div``;
