import styled from "styled-components";

export const GalleryCarouselWrapper = styled.div`
  .carousel {
    &.carousel-slider .control-arrow {
      width: 80px;
      height: 80px;
      top: calc(50% - 40px) !important;
      background: #0043ff;
      opacity: 1;
      :hover {
        background: #0043ff;
      }
      &.control-prev {
        margin-left: calc(17.5% - 40px);
      }
      &.control-next {
        margin-right: calc(17.5% - 40px);
      }
      @media (max-width: 768px) {
        width: 36px;
        height: 36px;
        top: calc(50% - 32px) !important;
        &.control-prev {
          margin-left: calc(6% - 18px);
          padding: 0;
        }
        &.control-next {
          margin-right: calc(6% - 18px);
          padding: 0;
        }
      }
    }
    .control-dots {
      margin: 0;
      position: relative;
      margin-top: 40px !important;
      padding-left: 0;
      .dot {
        background: #d7e2ff;
        box-shadow: none;
        opacity: 1;
        width: 16px;
        height: 16px;
        &.selected,
        :hover {
          background: #0043ff;
        }
      }
      @media (max-width: 768px) {
        margin-top: 15px !important;
        .dot {
          width: 12px;
          height: 12px;
          margin: 0 4px;
        }
      }
    }
    li.slide {
      background: transparent !important;
      opacity: 0.4;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      img {
        width: 96% !important;
        height: calc(100% - 76px) !important;
        @media (max-width: 768px) {
          width: 98% !important;
          height: 86% !important;
        }
      }
    }
    li.slide.selected {
      opacity: 1;
      img {
        width: 100%;
        height: 100% !important;
      }
    }
  }
`;
