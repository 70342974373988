import React from "react";
import PropTypes from "prop-types";

import {
  PressCardWrapper,
  Image,
  Content,
  Title,
  Date,
  ImageContainer
} from "./styles/PressCardStyles";

const PressCard = ({ data }) => {
  const { title, parutionsPresse } = data;
  return (
    <React.Fragment>
      <PressCardWrapper className="press-card" href={parutionsPresse && parutionsPresse.lien ? parutionsPresse.lien : "#"} target="_blank">
        {parutionsPresse && parutionsPresse.logo && <ImageContainer><Image src={parutionsPresse.logo.sourceUrl} /></ImageContainer>}
        <Content>
          {title && <Title>{title}</Title>}
          {parutionsPresse && parutionsPresse.date && <Date>{parutionsPresse.date}</Date>}
        </Content>
      </PressCardWrapper>
    </React.Fragment>
  );
};

PressCard.propTypes = {
  featured: PropTypes.bool,
  odd: PropTypes.bool
};

PressCard.defaultProps = {
  featured: false,
  odd: false
};

export default PressCard;
