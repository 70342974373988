import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRESS_LIST_PAGE_DATA } from "graphql/get_press_list_page_data";
import { GlobalContext } from "GlobalContext";
import PressCard from "components/PressCard";
import Paginator from "components/Paginator";

import { PressCardsWrapper, ListWrapper } from "./styles/PressListStyles";

import heroImg from "images/home-hero-image.png";

const PressList = (props) => {

  let pressListItems = null;
  let intro = null;
  let seo = null;

  /////// Pagination
  const [offset, setOffset] = useState(0);
  const perPage = 4;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData } = useContext(GlobalContext);

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_PRESS_LIST_PAGE_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("Press page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  if (pageGqlData) {
    pressListItems = pageGqlData.parutions_presse.nodes;
    intro = pageGqlData.page.heroPage.introduction;
    seo = pageGqlData.page.seo;
  }

  const handlePageClick = (e) => {
    const new_offset = e.selected * perPage;
    setOffset(new_offset);
    scrollToTop();
  };

  const scrollToTop = () => {
    const element = document.getElementById("listing");
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop - 40 : 0,
      });
    }, 100);
  };

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="fixed-container">
              {intro && (
                  <div className={`content-container white-bg no-title`}>
                    <div className="container">
                      <div dangerouslySetInnerHTML={{ __html: intro }} />
                    </div>
                  </div>
              )}
              <div className="container">
                {pressListItems && (
                  <ListWrapper id="listing">
                    <PressCardsWrapper>
                      {pressListItems
                        .slice(offset, offset + perPage)
                        .map((item, i) => (
                          <PressCard data={item} key={i} />
                        ))}
                    </PressCardsWrapper>
                    <Paginator
                      data={pressListItems}
                      perPage={perPage}
                      handlePageClick={handlePageClick}
                    />
                  </ListWrapper>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [activeMegaMenu, isLoading, offset]);
};

PressList.propTypes = {};

PressList.defaultProps = {};

export default PressList;
