import React, { useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import {
  NewsletterWrapper,
  Label,
  Form,
  Input,
  Button,
  SubscribeMsg,
} from "./styles/NewsletterStyles";

const Newsletter = (props) => {
  const { newsletterData, currentLang } = useContext(GlobalContext);
  const [emailInputValue, setEmailInputValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showGreeting, setShowGreeting] = useState(false);
  const {
    description,
    libelleBouton,
    placeholder,
    messageDeConfirmation,
    loading,
    error,
  } = newsletterData;

  const handleSubscription = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: emailInputValue }),
    };

    const client_key =
      currentLang === "en"
        ? "9e1bbe0d4479e212561264708e3dbabe64c9b160"
        : "b2f530b13b2c2b6b40ebd6961a182e8b2da51d55";
    const client_secret =
      currentLang === "en"
        ? "829216683e0115724bde9495fc1b09ca6e7c8379"
        : "8ca662cf2b91eb15c3d1b7dc5bab6caf9d13faf7";

    fetch(
      `http://ia-backend.innoveos.com/wp-json/newsletter/v2/subscribers?client_key=${client_key}&client_secret=${client_secret}`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error response status
          return Promise.reject(response.status);
        }
        setShowGreeting(true);
      })
      .catch((error) => {
        error === 400
          ? setErrorMsg(
              currentLang === "en"
                ? "This email already exists!"
                : "Cet email est déjà abonné  notre newsletter!"
            )
          : setErrorMsg(
              currentLang === "en"
                ? "An error has occured. Try again !"
                : "Une erreur est survenu, merci de réessayer."
            );
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    if (errorMsg) {
      setShowGreeting(false);
      setTimeout(() => {
        setErrorMsg("");
      }, 8000);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (showGreeting) {
      setEmailInputValue("");
      setErrorMsg("");
      setTimeout(() => {
        setShowGreeting(false);
      }, 8000);
    }
  }, [showGreeting]);

  useEffect(() => {
    error && console.log("Newsletter data loading error: ", error.message);
  }, [error]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <NewsletterWrapper>
          {description && (
            <Label>
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </Label>
          )}
          {placeholder && libelleBouton && (
            <Form onSubmit={(e) => handleSubscription(e)}>
              <Input
                type="email"
                placeholder={placeholder}
                value={emailInputValue}
                onChange={(e) => setEmailInputValue(e.target.value)}
              />
              <Button type="submit">{libelleBouton}</Button>
              {errorMsg && (
                <SubscribeMsg>
                  <span className="error">{errorMsg}</span>
                </SubscribeMsg>
              )}
              {showGreeting && (
                <SubscribeMsg>
                  <span>
                    {currentLang === "en"
                      ? "Thank you. you are now subscribed to our newsletter"
                      : "Félicitation, vous êtes maintenant abonné à notre newsletter!"}
                  </span>
                </SubscribeMsg>
              )}
            </Form>
          )}
        </NewsletterWrapper>
      </React.Fragment>
    );
  }, [newsletterData, emailInputValue, errorMsg, showGreeting]);
};

Newsletter.propTypes = {
  description: PropTypes.string,
  libelleBouton: PropTypes.string,
  placeholder: PropTypes.string,
  messageDeConfirmation: PropTypes.string,
};

Newsletter.defaultProps = {
  description: null,
  libelleBouton: null,
  placeholder: null,
  messageDeConfirmation: null,
};

export default Newsletter;
