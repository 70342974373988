import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useQuery } from "@apollo/react-hooks";
import { GET_EVENTS_PAGE_DATA } from "graphql/get_events_page_data";
import { GlobalContext } from "GlobalContext";
import {
  Intro,
  EventsWrapper,
  EventCover,
  SliderSectionTitle,
} from "./styles/EventsStyles";

import EventCard from "components/EventCard";
import EventsTimeline from "components/EventsTimeline";
import HistoryCarousel from "components/HistoryCarousel";

import heroImg from "images/home-hero-image.png";

const Events = (props) => {
  // Initial Values
  let eventsData = null;
  let ia = null;
  let auFilDuTempsBlockData = null;
  let intro = null;
  let seo = null;

  // Consuming Context
  const {
    navigateTo,
    activeMegaMenu,
    setHeroData,
    isLoading,
    setIsLoading,
  } = useContext(GlobalContext);

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_EVENTS_PAGE_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("Events page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  if (pageGqlData) {
    eventsData = pageGqlData.evenements;
    ia = pageGqlData.ia.institutAmadeus ? pageGqlData.ia.institutAmadeus : null;
    auFilDuTempsBlockData = ia ? ia.auFilDuTemps : null;
    intro = pageGqlData.page.heroPage.introduction;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="main-container">
              <div className="fixed-container">
                {intro && (
                    <div className={`content-container white-bg no-title`}>
                      <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: intro }} />
                      </div>
                    </div>
                )}
                {eventsData && (
                  <EventsWrapper>
                    <EventCover
                      bg={
                        eventsData.nodes[0].informationsEvenement
                          .visuelEvenement.sourceUrl
                      }
                    />
                    <div className="fixed-container">
                      <EventCard
                        data={eventsData.nodes[0].informationsEvenement}
                        onClick={() =>
                          navigateTo("/evenements/" + eventsData.nodes[0].slug)
                        }
                      />
                      <EventsTimeline data={eventsData.nodes} />
                    </div>
                  </EventsWrapper>
                )}
              </div>
            </div>
            {auFilDuTempsBlockData && (
              <div>
                <SliderSectionTitle>evenements passés</SliderSectionTitle>
                {auFilDuTempsBlockData.faitsMarquants && (
                  <HistoryCarousel
                    items={auFilDuTempsBlockData.faitsMarquants}
                    showFullDate
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

Events.propTypes = {};

Events.defaultProps = {};

export default Events;
