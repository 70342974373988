import styled from "styled-components";

export const MegaMenuWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 40px);
  background: #f1f5ff;
  z-index: 998;
  margin-top: -150px;
  @media (max-width: 768px) {
    margin-top: -52px;
    height: calc(100vh - 30px);
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  width: calc(100% - 150px);
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const NavigationWrapper = styled.div`
  width: 50%;
  height: calc(100% + 40px);
  background: #0043ff;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding: 14vh 2vw 14vh 9vw;
  justify-content: space-between;
  margin-top: -40px;
  @media (max-width: 768px) {
    margin-top: 0;
    width: 100%;
    height: 100%;
    padding: 40% 5% 5%;
    justify-content: initial;
  }
`;

export const MenuItem = styled.a`
  color: #fafbff;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  transition-delay: 0.1s;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
  }
  :before {
    transition: 0.5s;
    display: block;
    opacity: 0;
    content: "";
    width: 1000px;
    left: -1020px;
    height: 2px;
    border: 2px solid #fadeff;
    position: absolute;
    bottom: 15px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  :hover {
    margin-left: 30px;
    :before {
      opacity: 1;
    }
  }
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const CoverWrapper = styled.div`
  width: 50%;
  background: #0043ff;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -40px;
  min-height: calc(100% + 40px);
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;
