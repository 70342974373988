import { gql } from "apollo-boost";

export const GET_PRESS_LIST_PAGE_DATA = gql`
  {
    page(id: "cGFnZToyMjY=") {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    parutions_presse {
      nodes {
        title
        parutionsPresse {
          date
          lien
          logo {
            sourceUrl
          }
        }
      }
    }
  }
`;
