import React from "react";
import PropTypes from "prop-types";

import {
  MemberCardWrapper,
  Image,
  Content,
  Name,
  Occupation,
} from "./styles/MemberCardStyles";

const MemberCard = ({ featured, odd, data, onClick }) => {
  const { nomEtPrenom, fonction1, fonction2, image } = data;
  return (
    <React.Fragment>
      <MemberCardWrapper className="member-card" onClick={onClick}>
        <Image src={image.sourceUrl} />
        <Content odd={odd}>
          <Name featured={featured}>{nomEtPrenom}</Name>
          <Occupation featured={featured}>
            {fonction1} {fonction2}
          </Occupation>
        </Content>
      </MemberCardWrapper>
    </React.Fragment>
  );
};

MemberCard.propTypes = {
  featured: PropTypes.bool,
  odd: PropTypes.bool,
  onClick: PropTypes.func,
};

MemberCard.defaultProps = {
  featured: false,
  odd: false,
  onClick: () => {},
};

export default MemberCard;
