import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  padding-top: 180px;
  @media (max-width: 768px) {
    padding-top: 75px;
    flex-direction: column;
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  .post-card {
    margin-bottom: 100px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .paginator {
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    width: 100%;
    .post-card {
      margin-bottom: 30px;
    }
    .paginator {
      margin-top: 40px;
    }
  }
`;

export const MenuItem = styled.a`
  color: #0043ff;
  font-size: 1.34rem;
  font-weight: 300;
  margin-bottom: 45px;
  position: relative;
  cursor: pointer;
  padding-left: 20px;
  &.active {
    padding-left: 0px;
    font-weight: 500;
    :before {
      content: "";
      width: 1000px;
      left: -1020px;
      height: 2px;
      border: 2px solid #0043ff;
      position: absolute;
      bottom: 6px;
    }
  }
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 14px;
    font-size: 1rem;
    &.active {
      :before { 
        right: 102%;
        left: initial;
        border: 1px solid #0043ff;
      }
    }
  }
`;
