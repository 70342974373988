import React from "react";

import { RemoveBaseUrl } from "utils";

import {
  NavMenuWrapper,
  LogoContainer,
  Logo,
  NavMenuContainer,
  Menu,
  MenuItem,
  SocialIcons,
} from "./styles/NavMenuStyles";

const NavMenu = ({ data, navigateTo }) => {
  const { logo, menuItems, socials } = data;
  return (
    <React.Fragment>
      <NavMenuWrapper className="NavMenu">
        <LogoContainer>
          {logo && (
            <Logo
              src={logo}
              alt="logo-institut-amadeus"
              onClick={() => navigateTo("/")}
            />
          )}
        </LogoContainer>
        <NavMenuContainer>
          <Menu>
            {menuItems &&
              menuItems.map((menu, i) => (
                <MenuItem
                  onClick={() => navigateTo(RemoveBaseUrl(menu.url), true)}
                  key={i}
                >
                  {menu.label}
                </MenuItem>
              ))}
          </Menu>
          <SocialIcons>
            {socials &&
              socials.map((item, i) => (
                <a
                  href={item.lien}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.icone.sourceUrl} alt={item.plateforme} />
                </a>
              ))}
          </SocialIcons>
        </NavMenuContainer>
      </NavMenuWrapper>
    </React.Fragment>
  );
};

NavMenu.propTypes = {};

NavMenu.defaultProps = {};

export default NavMenu;
