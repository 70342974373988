import React, { useContext } from "react";
import PropTypes from "prop-types";
import Link from "ui/Link";
import { GlobalContext } from "GlobalContext";
import TrainingCard from "components/TrainingCard";

import {
  TrainingsBlockWrapper,
  TrainingsGrid,
  TrainingsIntro,
  IntroContainer,
  Title,
  Description,
} from "./styles/TrainingsBlockStyles";

const TrainingsBlock = ({ formationsData }) => {
  const { navigateTo } = useContext(GlobalContext);
  const {
    titreRubrique,
    description,
    imageArrierePlan,
    formations,
    labelToutesLesFormations,
    lienToutesLesFormations,
  } = formationsData;
  return (
    <React.Fragment>
      <TrainingsBlockWrapper
        className="formations-block"
        bg={imageArrierePlan ? imageArrierePlan.sourceUrl : ""}
      >
        <div className="fixed-container">
          <TrainingsGrid>
            <TrainingsIntro>
              <IntroContainer>
                <Title
                  className="secondary"
                  dangerouslySetInnerHTML={{ __html: titreRubrique }}
                />
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </IntroContainer>
            </TrainingsIntro>
            {formations &&
              formations.map((formation, i) => (
                <TrainingCard key={i} data={formation.formation} />
              ))}
          </TrainingsGrid>
          <Link label={labelToutesLesFormations} color="white" onClick={() => navigateTo(lienToutesLesFormations)} />
        </div>
      </TrainingsBlockWrapper>
    </React.Fragment>
  );
};

TrainingsBlock.propTypes = {};

TrainingsBlock.defaultProps = {};

export default TrainingsBlock;
