import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  IAWrapper,
  Title,
  Content,
  Slogan,
  Container,
  TitleWithLine,
} from "./styles/InstitutAmadeusStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_IA_PAGE_DATA } from "graphql/get_ia_page_data";
import { GlobalContext } from "GlobalContext";

import TeamBlock from "components/TeamBlock";
import VideoCover from "components/VideoCover";
import VideoPlayer from "components/Header/VideoPlayer";
import HistoryCarousel from "components/HistoryCarousel";

const InstitutAmadeus = (props) => {
  const [showVideo, setShowVideo] = useState(false);

  // Initial Values
  let ia = null;
  let iaBlockData = null;
  let videoBlockData = null;
  let auFilDuTempsBlockData = null;
  let equipeBlockData = null;
  let intro = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzA5" : "cGFnZToyMTQ=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_IA_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "Institut Amadeus page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    ia = pageGqlData.page.institutAmadeus
      ? pageGqlData.page.institutAmadeus
      : null;
    iaBlockData = ia ? ia.presentationIa : null;
    videoBlockData = ia ? ia.video : null;
    auFilDuTempsBlockData = ia ? ia.auFilDuTemps : null;
    equipeBlockData = ia ? ia.equipe : null;
    intro = pageGqlData.page.heroPage.introduction;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <IAWrapper>
              {iaBlockData && (
                <div className="fixed-container">
                  {intro && (
                    <div className={`content-container white-bg no-title`}>
                      <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: intro }} />
                      </div>
                    </div>
                  )}
                  <div className={`content-container white-bg no-title`}>
                    <div className="container">
                      <Title
                        dangerouslySetInnerHTML={{
                          __html: iaBlockData.titre,
                        }}
                      />
                      <Content
                        dangerouslySetInnerHTML={{
                          __html: iaBlockData.contenu,
                        }}
                      />
                      <Slogan>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: iaBlockData.signature,
                          }}
                        ></span>
                      </Slogan>
                    </div>
                  </div>
                </div>
              )}

              {videoBlockData &&
                (showVideo ? (
                  <VideoPlayer
                    videoId={videoBlockData.idVideo}
                    setShowVideo={setShowVideo}
                  />
                ) : (
                  <VideoCover
                    title={videoBlockData.titreVideo}
                    bg={videoBlockData.miniatureVideo.sourceUrl}
                    setShowVideo={setShowVideo}
                  />
                ))}

              {auFilDuTempsBlockData && (
                <div>
                  <Container>
                    <div className="fixed-container">
                      <TitleWithLine
                        dangerouslySetInnerHTML={{
                          __html: auFilDuTempsBlockData.titreRubrique,
                        }}
                      />
                      {auFilDuTempsBlockData.introduction && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: auFilDuTempsBlockData.introduction,
                          }}
                        />
                      )}
                    </div>
                  </Container>

                  {auFilDuTempsBlockData.faitsMarquants && (
                    <HistoryCarousel
                      items={auFilDuTempsBlockData.faitsMarquants}
                    />
                  )}

                  {auFilDuTempsBlockData.contenuFinal && (
                    <Container>
                      <div className="fixed-container">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: auFilDuTempsBlockData.contenuFinal,
                          }}
                        />
                      </div>
                    </Container>
                  )}
                </div>
              )}

              {equipeBlockData && <TeamBlock data={equipeBlockData} />}
            </IAWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, showVideo, activeMegaMenu, isLoading]);
};

InstitutAmadeus.propTypes = {};

InstitutAmadeus.defaultProps = {};

export default InstitutAmadeus;
