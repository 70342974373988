import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  TrainingPackagedDetailsWrapper,
  TrainingObjectivesWrapper,
  GalleryCarouselWrapper,
  TrainingModulesWrapper,
  ModulesSectionTitle,
  CtaWrapper,
  Text,
  SubText,
  ContactInfos,
  Email,
} from "./styles/TrainingPackagedDetailsStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_FORMATION_PACKAGEE_DETAILS_PAGE_DATA } from "graphql/get_formation_packagee_details_page_data";
import { GlobalContext } from "GlobalContext";

import TrainingObjectives from "components/TrainingObjectives";
import TrainingModule from "components/TrainingModule";
import GalleryCarousel from "components/GalleryCarousel";

import heroBg from "images/home-hero-image.png";

const TrainingPackagedDetails = (props) => {
  // Initial Values
  let intro = null;
  let objectifs = null;
  let cible = null;
  let dureeDeLaFormation = null;
  let images = null;
  let modules = null;
  let contactFormation = null;
  let seo = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData, currentLang } =
    useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE4" : "cGFnZToyMzI=";

  // GraphQl Queries
  const slug = props.match.params.slug;
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_FORMATION_PACKAGEE_DETAILS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug, pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.formation_packageeBy.title,
        titre2: pageGqlData.formation_packageeBy.formationsPackagees.sousTitre,
        image: {
          sourceUrl: pageGqlData.formation_packageeBy.formationsPackagees
            .imageHero
            ? pageGqlData.formation_packageeBy.formationsPackagees.imageHero
                .sourceUrl
            : null,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "'Formation packagée détails' details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    intro = pageGqlData.formation_packageeBy.formationsPackagees.descriptif;
    objectifs = pageGqlData.formation_packageeBy.formationsPackagees.objectifs;
    cible = pageGqlData.formation_packageeBy.formationsPackagees.cible;
    dureeDeLaFormation =
      pageGqlData.formation_packageeBy.formationsPackagees.dureeDeLaFormation;
    images = pageGqlData.formation_packageeBy.formationsPackagees.images;
    modules = pageGqlData.formation_packageeBy.formationsPackagees.modules;
    contactFormation =
      pageGqlData.formation_packageeBy.formationsPackagees.contactFormation;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <TrainingPackagedDetailsWrapper>
              {objectifs && (
                <div className="fixed-container">
                  {intro && (
                    <div className={`content-container white-bg no-title`}>
                      <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: intro }} />
                      </div>
                    </div>
                  )}
                  <TrainingObjectivesWrapper>
                    <h2>{currentLang === "en" ? "Objectives" : "Objectifs"}</h2>
                    <TrainingObjectives
                      objectifs={objectifs}
                      cible={cible}
                      dureeDeLaFormation={dureeDeLaFormation}
                    />
                  </TrainingObjectivesWrapper>
                </div>
              )}

              {images && (
                <GalleryCarouselWrapper>
                  <GalleryCarousel images={images} />
                </GalleryCarouselWrapper>
              )}

              {modules && (
                <TrainingModulesWrapper>
                  <div className="fixed-container">
                    <ModulesSectionTitle>
                      {currentLang === "en" ? "Units" : "Modules"}
                    </ModulesSectionTitle>
                    {modules.map(
                      (module, i) =>
                        module.titreModule && (
                          <TrainingModule
                            data={module}
                            key={i}
                            number={i}
                            odd={i % 2 === 0}
                          />
                        )
                    )}
                  </div>
                </TrainingModulesWrapper>
              )}

              {contactFormation && (
                <CtaWrapper>
                  <div className="fixed-container">
                    <SubText
                      className="fixed-container"
                      dangerouslySetInnerHTML={{ __html: contactFormation }}
                    />
                  </div>
                </CtaWrapper>
              )}
            </TrainingPackagedDetailsWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

TrainingPackagedDetails.propTypes = {};

TrainingPackagedDetails.defaultProps = {};

export default TrainingPackagedDetails;
