import styled from "styled-components";

export const ErrorMessage = styled.div`
  display: flex;
  color: #0043ff;
  font-size: 54px;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
  text-transform: uppercase;
`;
