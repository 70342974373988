import { gql } from "apollo-boost";

export const GET_CAREER_DETAILS_PAGE_DATA = gql`
  query($slug: String!, $pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    offre_emploiBy(slug: $slug) {
      date
      title
      slug
      offre_emploi {
        typeOffre
        typeContrat
        detailsOffre {
          titre
          contenu
        }
        blocContact
      }
    }
  }
`;
