import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import {
  EventCardWrapper,
  EventProgramWrapper,
  EventProgramLabel,
  CtaWrapper,
  Text,
  SubText,
  ContactInfos,
  Email,
} from "./styles/EventDetailsStyles";
import { extractDate } from "utils";
import { useQuery } from "@apollo/react-hooks";
import { GET_EVENT_DETAILS_DATA } from "graphql/get_event_details_page_data";
import { GlobalContext } from "GlobalContext";

import EventCard from "components/EventCard";
import ContentBlock from "components/ContentBlock";
import EventProgram from "components/EventProgram";

const EventDetails = (props) => {
  // Initial Values
  let eventCardData = null;
  let contentBlocs = null;
  let eventProgramData = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData } = useContext(
    GlobalContext
  );

  // GraphQl Queries
  const slug = props.match.params.slug;
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_EVENT_DETAILS_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        image: {
          altText:
            pageGqlData.evenementBy.informationsEvenement.visuelEvenement
              .altText,
          sourceUrl:
            pageGqlData.evenementBy.informationsEvenement.visuelEvenement
              .sourceUrl,
        },
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "Event Details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    eventCardData = {
      dateDebut: pageGqlData.evenementBy.informationsEvenement.dateDebut,
      dateFin: pageGqlData.evenementBy.informationsEvenement.dateFin,
      lieu: pageGqlData.evenementBy.informationsEvenement.lieu,
      theme: pageGqlData.evenementBy.informationsEvenement.theme,
      titre: pageGqlData.evenementBy.informationsEvenement.titre,
      type: pageGqlData.evenementBy.informationsEvenement.type,
    };
    contentBlocs = pageGqlData.evenementBy.blocContenu.blocsContenus;
    const programmeEvenement = pageGqlData.evenementBy.programmeEvenement;
    eventProgramData = programmeEvenement.programme
      ? programmeEvenement.programme
      : null;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            {eventCardData && eventCardData.titre && (
              <div className="fixed-container">
                <EventCardWrapper>
                  <EventCard data={eventCardData} />
                </EventCardWrapper>
              </div>
            )}

            {contentBlocs && (
              <div className="fixed-container">
                <div
                  className={`content-container white-bg noHorizontalPad ${
                    (!eventCardData || !eventCardData.titre) && "no-title"
                    }`}
                >
                  {contentBlocs &&
                    contentBlocs.map((block, i) => (
                      <ContentBlock key={i} block={block} />
                    ))}
                </div>
              </div>
            )}

            {eventProgramData && (
              <EventProgramWrapper>
                <div className="fixed-container">
                  <EventProgramLabel>Programme</EventProgramLabel>
                  {eventProgramData.map(
                    (program, i) =>
                      program.titre && (
                        <EventProgram
                          data={program}
                          odd={i % 2 === 0}
                          key={i}
                        />
                      )
                  )}
                </div>
              </EventProgramWrapper>
            )}

            <CtaWrapper>
              <div className="fixed-container">
                <Text>
                  Les inscriptions sont désormais ouvertes sur le site
                  www.medays.org
                </Text>
                <SubText>
                  N’hésitez pas à nous contacter pour plus d’informations ou
                  inscription.
                </SubText>
                <ContactInfos>
                  <span>Me Imane ISSELMANE</span>
                  <span>+212 537 71 42 34</span>
                  <Email>evenements@institutamadeus.com</Email>
                </ContactInfos>
              </div>
            </CtaWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

EventDetails.propTypes = {};

EventDetails.defaultProps = {};

export default EventDetails;
