import React, { useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import VideoPlayer from "./VideoPlayer";

import playBtnSVG from "images/play.svg";

import {
  HeroWrapper,
  TitleContainer,
  Title,
  BigSubtitle,
  ImageContainer,
  Image,
  VideoBtnContainer,
  VideoLink,
  PlayBtn,
  Label,
} from "./styles/HeroStyles";

const Hero = (props) => {
  const [showVideo, setShowVideo] = useState(false);

  const { heroData } = useContext(GlobalContext);

  return useMemo(() => {
    const {
      titre1,
      titre2,
      labelLectureVideo,
      idVideo,
      image,
      loading,
      error,
    } = heroData;

    error && console.log("Navbar data loading error: ", error);

    return (
      <React.Fragment>
        {showVideo && idVideo ? (
          <VideoPlayer videoId={idVideo} setShowVideo={setShowVideo} />
        ) : (
          <HeroWrapper className="hero">
            <TitleContainer className={BigSubtitle ? "right" : ""}>
              {titre1 && <Title>{titre1}</Title>}
              {titre2 && <BigSubtitle>{titre2}</BigSubtitle>}
            </TitleContainer>
            <ImageContainer>
              {image && <Image src={image.sourceUrl} alt={image.altText} />}
              {labelLectureVideo && (
                <VideoBtnContainer>
                  <VideoLink>
                    <PlayBtn onClick={() => setShowVideo(true)}>
                      <img src={playBtnSVG} alt="play" />
                    </PlayBtn>
                    <Label>{labelLectureVideo}</Label>
                  </VideoLink>
                </VideoBtnContainer>
              )}
            </ImageContainer>
          </HeroWrapper>
        )}
      </React.Fragment>
    );
  }, [heroData, showVideo]);
};

Hero.propTypes = {
  titre1: PropTypes.string,
  titre2: PropTypes.string,
  labelLectureVideo: PropTypes.string,
  idVideo: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  miniatureVideo: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
};

Hero.defaultProps = {
  titre1: null,
  titre2: null,
  labelLectureVideo: null,
  idVideo: null,
  image: {
    altText: null,
    sourceUrl: null,
  },
  miniatureVideo: {
    altText: null,
    sourceUrl: null,
  },
};

export default Hero;
