import styled from "styled-components";

export const CollapsibleWrapper = styled.div`
  background: rgba(255, 255, 255, 0.7);
  border: 6px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  padding: 50px 30px 50px 40px;
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center right 90px;
  background-size: auto 120px;
  &.opened {
    background-color: #f1f5ff;
    border: 6px solid rgba(4, 61, 186, 0.2);
    min-height: 380px;
    background-position: top 60px right 60px;
    background-size: 250px;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
    padding: 15px;
    background-position: center right 35px;
    background-size: auto 45px;
    &.opened {
      min-height: initial;
      background-position: top 15px right 15px;
      background-size: 100px;
    }
  }
`;

export const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: #043dba;
  font-weight: 300;
  span {
    font-size: 2.66rem;
    &.title {
      font-size: 3.83rem;
      font-weight: 500;
    }
    @media (max-width: 768px) {
      font-size: 1.2rem;
      &.title {
          font-size: 1.4rem;
      }
    }
  }
`;

export const LeftArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100%;
  width: 200px;
  @media (max-width: 768px) {
    width: initial;
    margin-left: 65px;
    img {
      width: 10px;
    }
  }
`;

export const ArrowIcon = styled.img`
  cursor: pointer;
`;

export const CollapsibleBody = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.3s;
  padding-top: 0;
  ${(props) => props.opened && "height: auto;padding-top: 30px;"}
  font-size: 1.91rem;
  @media (max-width: 768px) {
    padding-top: 10px;
    font-size: 1.2rem;
  }
  ul {
    list-style: none;
    padding-left: 45px;
    margin: 0;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      padding-left: 15px;
      margin-bottom: 0;
    }
    li {
      display: block;
      line-height: 2.66rem;
      padding: 8px 0;
      padding-left: 22px;
      position: relative;
      :before {
        content: "";
        width: 12px;
        height: 12px;
        background: rgba(0, 67, 255, 0.3);
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        border-radius: 100px;
        position: absolute;
        top: 18px;
        left: 0;
      }
      :after {
        content: "";
        width: 8px;
        height: 8px;
        background: #0043ff;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        border-radius: 100px;
        position: absolute;
        top: 20px;
        left: 2px;
      }
      @media (max-width: 768px) {
        line-height: 1.8rem;
        padding: 3px 0;
        padding-left: 22px;
        :before {
          top: .6rem;
        }
        :after {
          top: .7rem;
        }
      }
    }
  }
`;
