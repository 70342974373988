import styled from "styled-components";

export const PostDetailsWrapper = styled.div`
  .content-block {
    margin-bottom: 65px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
      p{
        margin-bottom: 15px;
      }
    }
  }
`;

export const EventCardWrapper = styled.div`
  .event-card {
    position: relative;
    background-color: rgba(0, 67, 255, 0.95);
    align-items: flex-end;
    .title {
      color: #fafbff;
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 50px;
    }
    @media (max-width: 768px) {
      .title {
        font-size: 1.25rem;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonBlock = styled.a`
  background-color: #1f1f98;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  padding: 35px 65px;
  color: #fafbff;
  font-size: 2.5rem;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 15px 15px 19px;
    font-size: 1rem;
    line-height: 1.25rem;
    min-width: 100%;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
`;
