import { gql } from "apollo-boost";

export const GET_TOPBAR_DATA = gql`
  query($langMenuId: ID!) {
    langues_menu: menu(id: $langMenuId) {
      menuItems {
        nodes {
          label
          url
          id
        }
      }
    }
  }
`;
