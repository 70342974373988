import styled from "styled-components";

export const VideoPlayerWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    height: calc(100vh - 190px);
    @media (max-width: 768px) {
      height: 32vh;
    }
  }
`;

export const CloseBtn = styled.a`
  height: 50px;
  width: 50px;
  display: flex;
  position: absolute;
  top: 80px;
  right: 25px;
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
  border: 2px solid #dadada;
  border-radius: 100px;
  transform: rotate(45deg);
  :before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 55%;
    border-left: 5px solid #043dba;
  }
  :after {
    content: "";
    display: block;
    position: absolute;
    width: 55%;
    height: 2px;
    border-top: 5px solid #043dba;
  }
  cursor: pointer;
  @media (max-width: 768px) {
    height: 46px;
    width: 46px;
    z-index: 999;
    top: -49px;
    right: 3px;
    border: none;
    :before {
      height: 50%;
      border-left: 2px solid #043dba;
    }
    :after {
      width: 50%;
      border-top: 2px solid #043dba;
    }
  }
`;
