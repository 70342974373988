import React from "react";
import PropTypes from "prop-types";
import {
  CollapsibleWrapper,
  CollapsibleHeader,
  Content,
  LeftArrow,
  CollapsibleBody,
  ArrowIcon,
} from "./styles/CollapsibleStyles";

import rightArrow from "images/icone-fleche-droite.svg";

const Collapsible = ({ data, id, opened, setOpenedCollapsibleId }) => {
  const { titre1, titre2, contenu, icone } = data;
  const handleOpen = (id) => {
    setOpenedCollapsibleId(id);
  };
  return (
    <React.Fragment>
      <CollapsibleWrapper
        className={`collapsible ${opened && "opened"}`}
        bg={icone.sourceUrl}
      >
        <CollapsibleHeader>
          <Content>
            <span dangerouslySetInnerHTML={{ __html: titre1 }} />
            <span
              className="title"
              dangerouslySetInnerHTML={{ __html: titre2 }}
            />
          </Content>
          <LeftArrow>
            {!opened && (
              <ArrowIcon src={rightArrow} onClick={() => handleOpen(id)} />
            )}
          </LeftArrow>
        </CollapsibleHeader>
        <CollapsibleBody
          opened={opened}
          dangerouslySetInnerHTML={{ __html: contenu }}
        />
      </CollapsibleWrapper>
    </React.Fragment>
  );
};

Collapsible.propTypes = {
  id: PropTypes.number,
  opened: PropTypes.bool,
};

Collapsible.defaultProps = {
  id: 0,
  opened: false,
};

export default Collapsible;
