import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { useQuery } from "@apollo/react-hooks";
import { GET_MENTIONS_PAGE_DATA } from "graphql/get_mentions_page_data";
import { GlobalContext } from "GlobalContext";
import ContentBlock from "components/ContentBlock";

import heroImg from "images/home-hero-image.png";

const Contact = (props) => {
  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzIw" : "cGFnZToyNTk=";

  let contentBlocs = null;
  let seo = null;

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_MENTIONS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  if (pageGqlData) {
    contentBlocs = pageGqlData.page.blocContenu.blocsContenus;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            {contentBlocs && (
              <div className="fixed-container">
                <div className="content-container white-bg no-title">
                  {contentBlocs &&
                    contentBlocs.map((block, i) => (
                      <ContentBlock key={i} block={block} />
                    ))}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [activeMegaMenu, isLoading]);
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
