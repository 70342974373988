import styled from "styled-components";

export const Title = styled.h2`
  width: 382px;
  margin-bottom: 55px;
  line-height: 4.41rem;
  p {
    line-height: 4.41rem;
  }
  @media (max-width: 768px) {
    width: auto;
    margin-bottom: 8px;
    line-height: 1.66rem;
    p {
      line-height: 1.8rem;
    }
  }
`;

export const Content = styled.div`
  p {
    margin-bottom: 20px;
  }
`;

export const OffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const JobOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StageOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;

export const H2 = styled.h2`
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const OffersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .career-offer-card {
    width: 75%;
    max-width: 75%;
    margin-bottom: 60px;
    :last-child {
      margin-bottom: 0;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
      padding: 15px;
    }
  }
`;

export const CtaWrapper = styled.div`
  background: #0043ff;
  padding: 120px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

export const Text = styled.div`
  color: #fafbff;
  font-size: 3.33rem;
  line-height: 5rem;
  text-align: center;
  margin-bottom: 50px;
  padding: 0 85px;
  p,
  a {
    color: #fafbff;
    font-size: 3.33rem;
    line-height: 5rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 1.1rem;
    line-height: 1.33rem;
    margin-bottom: 15px;
    p,
    a {
      font-size: 1.1rem;
      line-height: 1.33rem;
    }
  }
`;

export const EmailLink = styled.div`
  color: #fafbff;
  font-size: 2.5rem;
  text-align: center;
  text-decoration: underline;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
