import styled from "styled-components";

export const SubMenuWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SubMenuContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #fafbff;
  flex: 1;
  padding-left: 16%;
  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const Menu = styled.div`
  padding: 5px;
  width: 81%;
  min-width: 700px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const MenuItem = styled.span`
  padding: 0 18px;
  color: #fafbff;
  font-size: 1rem;
  font-weight: 100;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  a {
    cursor: pointer;
    color: #fafbff;
    text-decoration: none;
  }
  p {
    font-size: 1.08rem;
    line-height: initial;
    margin: 0;
    color: #fafbff;
  }
  @media (max-width: 768px) {
    font-size: 0.81rem;
    margin-bottom: 15px;
  }
`;

export const MenuItemLink = styled.span`
  cursor: pointer;
`;

export const Author = styled.div`
  display: flex;
  padding: 5px;
  b {
    margin-left: 5px;
  }
  a {
    color: #fafbff;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    font-size: 0.81rem;
  }
`;

export const SocialIcons = styled.div`
  display: none;
  a {
    display: flex;
    align-items: center;
    margin-left: 14px;
  }
  @media (max-width: 768px) {
    display: flex;
    margin-top: 17px;
    margin-bottom: 22px;
    a {
      margin-left: 20px;
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;
