import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile } from "react-device-detect";

import { GalleryCarouselWrapper } from "./styles/GalleryCarouselStyles";

const GalleryCarousel = ({ images }) => {
  return (
    <React.Fragment>
      <GalleryCarouselWrapper>
        <Carousel
          centerMode
          swipeable
          infiniteLoop
          centerSlidePercentage={isMobile ? 90 : 65}
          showStatus={false}
          showThumbs={false}
        >
          {images &&
            images.map((image, i) => (
              <div key={i}>
                <img src={image.sourceUrl} />
              </div>
            ))}
        </Carousel>
      </GalleryCarouselWrapper>
    </React.Fragment>
  );
};

GalleryCarousel.propTypes = {};

GalleryCarousel.defaultProps = {};

export default GalleryCarousel;
