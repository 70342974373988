import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { GlobalContext } from "GlobalContext";
import { useQuery } from "@apollo/react-hooks";
import { GET_CONTACT_PAGE_DATA } from "graphql/get_contact_page_data";
import {
  Wrapper,
  Block,
  Title,
  ContactCardsWrapper,
  ContactCard,
  Phone,
  Fax,
  Email,
  ContactInfosWrapper,
} from "./styles/ContactStyles";
import Link from "ui/Link";

import heroImg from "images/home-hero-image.png";

const Contact = (props) => {
  let blocSiege = null;
  let autresContacts = null;
  let intro = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    navigateTo,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE3" : "cGFnZToyMjg=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_CONTACT_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  if (pageGqlData) {
    blocSiege = pageGqlData.page.contact.blocSiege;
    autresContacts = pageGqlData.page.contact.autresContacts;
    intro = pageGqlData.page.heroPage.introduction;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="fixed-container">
              {intro && (
                <div className={`content-container white-bg no-title`}>
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                  </div>
                </div>
              )}
              <div className={`content-container white-bg no-title`}>
                <Wrapper>
                  {blocSiege && (
                    <Block>
                      <Title
                        dangerouslySetInnerHTML={{
                          __html: blocSiege.titreBloc,
                        }}
                      />
                      <ContactCardsWrapper>
                        <ContactCard>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: blocSiege.adresse,
                            }}
                          />
                          <Link
                            label={blocSiege.titreItiniraire}
                            onClick={() =>
                              window.open(blocSiege.urlItiniraire, "_blank")
                            }
                          />
                        </ContactCard>
                        <ContactCard>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: blocSiege.coordonnees,
                            }}
                          />
                        </ContactCard>
                      </ContactCardsWrapper>
                    </Block>
                  )}
                  {autresContacts &&
                    autresContacts.map((contact, i) => (
                      <Block key={i}>
                        <Title
                          dangerouslySetInnerHTML={{
                            __html: contact.titre,
                          }}
                        />
                        <ContactInfosWrapper>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: contact.coordonnees,
                            }}
                          />
                        </ContactInfosWrapper>
                      </Block>
                    ))}
                </Wrapper>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [activeMegaMenu, isLoading]);
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
