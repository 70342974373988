import styled from "styled-components";

export const EventCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: #0043ff;
  color: #fafbff;
  position: relative;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const EventDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const DetailsContainer = styled.div`
  font-size: 1.82rem;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    line-height: 1.8rem;
      font-size: 1.2rem;
    max-width: 76%;
  }
`;

export const EventTitle = styled.div`
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    margin-bottom: 4px;
    margin-top: 12px;
  }
`;

export const EventDescription = styled.div`
  font-size: 1.82rem;
  text-transform: uppercase;
  @media (max-width: 768px) {
    line-height: 1.8rem;
      font-size: 1.2rem;
  }
`;

export const RightDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 3.5rem;
  font-weight: 300;
  justify-content: center;
  height: fit-content;
  text-align: right;
  text-transform: capitalize;
  line-height: 4.16rem;
  b {
    font-size: 5rem;
    &.small {
      font-size: 3.75rem;
    }
  }
  span.small {
    font-size: 3.33rem;
  }
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    position: absolute;
    right: 15px;
    top: 15px;
    b {
      font-size: 1.4rem;
      &.small {
        font-size: 1.1rem;
      }
    }
    span.small {
      font-size: 1rem;
    }
  }
`;

export const MonthYear = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;