import styled from "styled-components";

export const TrainingPackagedDetailsWrapper = styled.div`
  h2 {
    margin-bottom: 45px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`;

export const TrainingObjectivesWrapper = styled.div`
  margin-top: 100px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const GalleryCarouselWrapper = styled.div`
  margin-top: 120px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const TrainingModulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  background: #fafbff;
  padding: 60px 0 105px 0;
  .event-program-item {
    margin-bottom: 20px;
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 50px 0;
  }
`;

export const ModulesSectionTitle = styled.h2`
  margin-bottom: 60px;
`;

export const CtaWrapper = styled.div`
  background: #0043ff;
  padding: 55px 0;
  color: #fafbff;
  font-size: 2.5rem;
  text-align: center;
  p{
    color: #fafbff;
    font-size: 2.5rem;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 30px 0;
    p{
      font-size: 1.1rem;
    }
  }
`;

export const Text = styled.div`
  font-size: 3.33rem;
  line-height: 5rem;
  margin-bottom: 40px;
  padding: 0 125px;
  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 15px;
  }
`;

export const SubText = styled.div`
  margin-bottom: 60px;
  padding: 0 125px;
  @media (max-width: 768px) {
    padding: 0 20px;
    margin-bottom: 15px;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const ContactInfos = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin-top: 20px;
    @media (max-width: 768px) {
      margin-top: 4px;
    }
  }
`;

export const Email = styled.span`
  text-decoration: underline;
`;
