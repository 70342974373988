import moment from "moment";
import "moment/locale/fr";

moment().locale("fr");

export const parseDate = (date) => {
  return moment(date, "DD/MM/YYYY");
};

export const extractDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const getDay = (date) => {
  return moment(date).format("DD");
};

export const getMonth = (date, lang = "fr") => {
  return moment(date).locale(lang).format("MMMM");
};

export const getYear = (date) => {
  return moment(date).format("YYYY");
};

export const RemoveBaseUrl = (url) => {
  /*
   * Replace base URL in given string, if it exists, and return the result.
   *
   * e.g. "http://localhost:8000/api/v1/blah/" becomes "/api/v1/blah/"
   *      "/api/v1/blah/" stays "/api/v1/blah/"
   */
  let baseUrlPattern = /(?:\w+:)?\/\/[^\/]+([^]+)/;

  let match = baseUrlPattern.exec(url);
  if (match != null) {
    return match[1];
  }

  return "/";
};
