import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  TrainingObjectivesWrapper,
  LeftBlock,
  ObjectivesList,
  TrainingDuration,
  RightBlock,
  Title,
  Label,
  Duration,
} from "./styles/TrainingObjectivesStyles";

import { GlobalContext } from "GlobalContext";

const TrainingObjectives = ({ objectifs, cible, dureeDeLaFormation }) => {
  // Consuming Context
  const { currentLang } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <TrainingObjectivesWrapper className="formation-objectives">
        <LeftBlock>
          <ObjectivesList>
            <div dangerouslySetInnerHTML={{ __html: objectifs }} />
          </ObjectivesList>
          <TrainingDuration>
            <Label>
              {currentLang === "en"
                ? "TRAINING DURATION"
                : "DUREE DE LA FORMATION"}
            </Label>
            <Duration>{dureeDeLaFormation}</Duration>
          </TrainingDuration>
        </LeftBlock>
        <RightBlock>
          <Title>{currentLang === "en" ? "Target" : "Cible"}</Title>
          <div dangerouslySetInnerHTML={{ __html: cible }} />
        </RightBlock>
      </TrainingObjectivesWrapper>
    </React.Fragment>
  );
};

TrainingObjectives.propTypes = {};

TrainingObjectives.defaultProps = {};

export default TrainingObjectives;
