import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  TrainingBespokeWrapper,
  CollapsibleList,
} from "./styles/TrainingBespokeStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_FORMATIONS_SUR_MESURE_PAGE_DATA } from "graphql/get_formations_sur_mesure_page_data";
import { GlobalContext } from "GlobalContext";

import Collapsible from "../../components/Collapsible/Collapsible";
import TrainingsBlock from "components/TrainingsBlock";

const TrainingBespoke = (props) => {
  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(0);

  // Initial Values
  let intro = null;
  let formationsData = null;
  let collapsiblesList = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZToyMzA=" : "cGFnZToyMzA=";

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_FORMATIONS_SUR_MESURE_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "'Formations sur mesure' page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    intro = pageGqlData.page.formationsSurMesure
      ? pageGqlData.page.formationsSurMesure.introduction
      : null;

    formationsData = pageGqlData.page.formationsSurMesure
      ? pageGqlData.page.formationsSurMesure.blocOffresDeFormations
      : null;

    collapsiblesList = pageGqlData.page.formationsSurMesure
      ? pageGqlData.page.formationsSurMesure.phases
      : null;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <TrainingBespokeWrapper>
              <div className="fixed-container">
                {intro && (
                  <div className={`content-container white-bg no-title`}>
                    <div className="container">
                      <div dangerouslySetInnerHTML={{ __html: intro }} />
                    </div>
                  </div>
                )}
                {collapsiblesList && (
                  <CollapsibleList>
                    {collapsiblesList.map((collapsibleData, i) => (
                      <Collapsible
                        data={collapsibleData}
                        key={i}
                        id={i}
                        opened={openedCollapsibleId === i}
                        setOpenedCollapsibleId={(id) =>
                          setOpenedCollapsibleId(id)
                        }
                      />
                    ))}
                  </CollapsibleList>
                )}
              </div>

              {formationsData && formationsData.afficherLeBloc === "oui" && (
                <TrainingsBlock formationsData={formationsData} />
              )}
            </TrainingBespokeWrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, openedCollapsibleId, activeMegaMenu, isLoading]);
};

TrainingBespoke.propTypes = {};

TrainingBespoke.defaultProps = {};

export default TrainingBespoke;
