import styled from "styled-components";

export const IAWrapper = styled.div`
  .video-cover {
    margin-top: 120px;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  }
  .video-player {
    margin-top: 120px;
    @media (max-width: 768px) {
      margin-top: 70px;
    }
  }
`;

export const Title = styled.h2`
  width: 220px;
  margin-bottom: 55px;
  line-height: 4.41rem;
  p {
    line-height: 4.41rem;
  }
  @media (max-width: 768px) {
    width: 69px;
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    p {
      font-size: 1.6rem;
      line-height: 1.8rem;  
    }
  }
`;

export const Content = styled.div`
  margin-bottom: 80px;
  p {
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const Slogan = styled.div`
  color: #043dba;
  font-size: 3.75rem;
  line-height: 4.41rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  p {
    color: #043dba;
    font-size: 3.75rem;
    line-height: 4.41rem;
  }
  @media (max-width: 768px) {
    font-size: 1.45rem;
    line-height: 1.58rem;
    p {
      font-size: 1.45rem;
      line-height: 1.58rem;
    }
  }
`;

export const Container = styled.div`
  padding: 85px 0 100px 0;
  p {
    margin-bottom: 20px;
    padding: 0 40px;
  }
  @media (max-width: 768px) {
    padding: 50px 0 30px 0;
    p {
      padding: 0;
    }
  }
`;

export const TitleWithLine = styled.div`
  color: #043dba;
  font-size: 3.75rem;
  line-height: 4.41rem;
  text-transform: uppercase;
  margin-bottom: 60px;
  position: relative;
  :after {
    content: "";
    width: 1000px;
    left: 415px;
    height: 1px;
    border: 1px solid #043dba;
    position: absolute;
    bottom: 10px;
  }
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 20px;
    :after {
      left: 200px;
      height: 0;
      border: none;
      border-bottom: 1px solid #043dba;
      bottom: 5px;
    }
  }
`;
