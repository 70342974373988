import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "GlobalContext";

import menuSVG from "images/grid-menu.svg";
import menuActiveSVG from "images/grid-menu-active.svg";
import menuGridSVG from "images/grid-menu-loader.svg";

import { RemoveBaseUrl } from "utils";

import {
  NavbarWrapper,
  LogoContainer,
  Logo,
  NavMenuContainer,
  NavMenu,
  MenuItem,
  Wrapper,
  SubMenu,
  SubMenuItem,
  MenuBtn,
} from "./styles/NavbarStyles";

const Navbar = (props) => {
  const {
    isLoading,
    activeMegaMenu,
    setActiveMegaMenu,
    navbarData,
    navigateTo,
  } = useContext(GlobalContext);

  let location = useLocation();

  const [hovered, setHovered] = useState(false);
  const [hoveredMenuId, setHoveredMenuId] = useState(0);
  const { logo, logoBleu, menuItems, loading, error } = navbarData;

  useEffect(() => {
    setHovered(hoveredMenuId !== 0);
  }, [hoveredMenuId]);

  useEffect(() => {
    error && console.log("Navbar data loading error: ", error.message);
  }, [error]);

  return useMemo(() => {
    const showSubMenu = (menuId) => {
      setHoveredMenuId(menuId);
    };
    const hideSubMenu = () => {
      setHoveredMenuId(0);
    };

    return (
      <React.Fragment>
        <NavbarWrapper className={`navbar ${hovered ? "hovered" : ""}`}>
          <NavMenuContainer onMouseLeave={() => hideSubMenu()}>
            <LogoContainer onClick={() => navigateTo("/")}>
              {logo && (
                <Logo
                  src={hovered && logoBleu ? logoBleu : logo}
                  alt="logo-institut-amadeus"
                />
              )}
            </LogoContainer>
            <NavMenu>
              {menuItems &&
                menuItems.map((menu, i) => (
                  <Wrapper
                    className="wrapper"
                    onMouseEnter={() => showSubMenu(menu.id)}
                    key={i}
                  >
                    <MenuItem
                      onClick={() => navigateTo(RemoveBaseUrl(menu.url), true)}
                      className={
                        hoveredMenuId === menu.id ||
                        location.pathname.startsWith(RemoveBaseUrl(menu.url)) ||
                        location.pathname === RemoveBaseUrl(menu.url)
                          ? "active"
                          : ""
                      }
                    >
                      {menu.label}
                    </MenuItem>
                    {menu.childItems.nodes.length > 0 && (
                      <SubMenu
                        className={
                          (i === menuItems.length - 1 && "last",
                          hoveredMenuId === menu.id ? "active" : "")
                        }
                      >
                        <ul>
                          {menu.childItems.nodes.map((submenuItem, i) => (
                            <li key={i}>
                              <SubMenuItem
                                onClick={() =>
                                  navigateTo(
                                    RemoveBaseUrl(submenuItem.url),
                                    true
                                  )
                                }
                                className={
                                  location.pathname.startsWith(
                                    RemoveBaseUrl(submenuItem.url)
                                  ) ||
                                  location.pathname ===
                                    RemoveBaseUrl(submenuItem.url)
                                    ? "active"
                                    : ""
                                }
                              >
                                {submenuItem.label}
                              </SubMenuItem>
                            </li>
                          ))}
                        </ul>
                      </SubMenu>
                    )}
                  </Wrapper>
                ))}
            </NavMenu>
          </NavMenuContainer>
          <MenuBtn onClick={() => setActiveMegaMenu(!activeMegaMenu)}>
            {activeMegaMenu ? (
              <img src={menuActiveSVG} alt="menu" />
            ) : isLoading ? (
              <img src={menuGridSVG} alt="menu" />
            ) : (
              <img src={menuSVG} alt="menu" />
            )}
          </MenuBtn>
        </NavbarWrapper>
      </React.Fragment>
    );
  }, [navbarData, isLoading, hovered, hoveredMenuId, activeMegaMenu]);
};

Navbar.propTypes = {
  logo: PropTypes.string,
  logoBleu: PropTypes.string,
  menuItems: PropTypes.array,
};

Navbar.defaultProps = {
  logo: null,
  logoBleu: null,
  menuItems: [],
};

export default Navbar;
