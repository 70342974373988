import styled from "styled-components";

export const MemberCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.odd ? "#fff" : "#d7e2ff")};
  padding: 18px 22px;
  width: 88%;
  flex: 1;
  margin-top: -70px;
  @media (max-width: 768px) {
    padding: 12px;
    margin-top: -40px;
  }
`;

export const Name = styled.div`
  color: #043dba;
  font-size: ${(props) => (props.featured ? "2.3rem" : "1.15rem")};
  line-height: ${(props) => (props.featured ? "2.75rem" : "1.41")};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  :after {
    margin-top: ${(props) => (props.featured ? "16px" : "9px")};
    display: block;
    content: "";
    width: 30px;
    height: 3px;
    background: #043dba;
  }
  @media (max-width: 768px) {
    font-size: ${(props) => (props.featured ? "1.2rem" : "1rem")};
    line-height: ${(props) => (props.featured ? "1.5rem" : "1.16rem")};
    :after {
      height: 1px;
      margin-top: ${(props) => (props.featured ? "8px" : "6px")};
    }
  }
`;

export const Occupation = styled.div`
  padding-top: ${(props) => (props.featured ? "12px" : "8px")};
  color: #043dba;
  font-size: ${(props) => (props.featured ? "1.5rem" : "0.8rem")};
  line-height: ${(props) => (props.featured ? "1.83rem" : "1rem")};
  text-align: center;
  @media (max-width: 768px) {
    padding-top: ${(props) => (props.featured ? "6px" : "6px")};
    font-size: ${(props) => (props.featured ? "1rem" : "0.8rem")};
  }
`;
