import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import PostCard from "components/PostCard";
import Link from "ui/Link";
import moment from "moment";

import { PostsBlockWrapper, Title, PostsGrid } from "./styles/PostsBlockStyles";

const PostsBlock = ({ publicationsList }) => {
  const { navigateTo, currentLang } = useContext(GlobalContext);
  const latestPosts = publicationsList
    ? publicationsList
        .sort(
          (a, b) =>
            moment(b.date).format("YYYYMMDD") -
            moment(a.date).format("YYYYMMDD")
        )
        .filter((a) => a.publications.global.miseEnAvantHome === "oui")
        .slice(0, 4)
    : null;
  return (
    <React.Fragment>
      <PostsBlockWrapper className="posts-block">
        <div className="fixed-container">
          <Title>{currentLang === "en" ? "Posts" : "Publications"}</Title>
          <PostsGrid>
            {latestPosts &&
              latestPosts.map((publication) => (
                <PostCard key={publication.id} data={publication} />
              ))}
          </PostsGrid>
          <Link
            label={
              currentLang === "en"
                ? "Read all posts"
                : "Lire toutes nos publications"
            }
            onClick={() => navigateTo("/publications")}
          />
        </div>
      </PostsBlockWrapper>
    </React.Fragment>
  );
};

PostsBlock.propTypes = {};

PostsBlock.defaultProps = {};

export default PostsBlock;
