import React from "react";
import PropTypes from "prop-types";
import MetierCard from "./MetierCard";

import {
  MetiersBlockWrapper,
  Title,
  MetiersGrid,
} from "./styles/MetiersBlockStyles";

const MetiersBlock = ({ metiersData }) => {
  const { titreRubrique, metier } = metiersData;
  return (
    <React.Fragment>
      <MetiersBlockWrapper className="metiers-block">
        <div className="fixed-container">
          <Title className="withline">{titreRubrique}</Title>
          <MetiersGrid>
            {metier &&
              metier.map((item, i) => (
                <MetierCard key={i} data={item.metier} />
              ))}
          </MetiersGrid>
        </div>
      </MetiersBlockWrapper>
    </React.Fragment>
  );
};

MetiersBlock.propTypes = {};

MetiersBlock.defaultProps = {};

export default MetiersBlock;
