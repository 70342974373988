import React, { useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import Carousel from "react-elastic-carousel";
import NewsCard from "components/NewsCard";
import Link from "ui/Link";

import {
  NewsCarouselWrapper,
  TitleWrapper,
  Title,
  NextArrow,
  MoreLinkWrapper,
} from "./styles/NewsCarouselStyles";
import arrowSvg from "images/icone-fleche-droite.svg";

const NewsCarousel = ({ newsList }) => {
  const { navigateTo } = useContext(GlobalContext);
  const carouselRef = useRef();
  const [tempIndex, setTempIndex] = useState(0);

  const onNextEnd = (currentItem) => {
    setTempIndex(currentItem.index);
  };

  const goToNextSlide = () => {
    if (tempIndex !== newsList.length - 2) {
      carouselRef.current.slideNext();
    } else {
      carouselRef.current.goTo(0);
      setTempIndex(0);
    }
  };

  return (
    <React.Fragment>
      <NewsCarouselWrapper className="news-carousel">
        <TitleWrapper className="fixed-container">
          <Title>Actualités liées</Title>
          <NextArrow
            src={arrowSvg}
            alt="Next"
            onClick={() => goToNextSlide()}
          />
        </TitleWrapper>
        <Carousel
          ref={carouselRef}
          onNextEnd={onNextEnd}
          itemsToShow={2}
          pagination={false}
          showArrows={false}
        >
          {newsList &&
            newsList.map((item, i) => (
              <NewsCard maxWidth="99%" key={i} data={item} />
            ))}
        </Carousel>
        <MoreLinkWrapper className="fixed-container">
          <Link
            label="Lire toutes nos actualités"
            onClick={() => navigateTo("/actualite")}
          />
        </MoreLinkWrapper>
      </NewsCarouselWrapper>
    </React.Fragment>
  );
};

NewsCarousel.propTypes = {};

NewsCarousel.defaultProps = {};

export default NewsCarousel;
