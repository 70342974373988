import { gql } from "apollo-boost";

export const GET_METIER_DETAILS_DATA = gql`
  query($slug: String!, $pageId: ID!, $homePageId: ID!) {
    hero_data: page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    metierBy(slug: $slug) {
      id
      title
      date
      slug
      visuelsMetier {
        visuelMetier {
          altText
          sourceUrl
        }
      }
      blocContenu {
        blocsContenus {
          ... on Metier_Bloccontenu_BlocsContenus_Titre {
            contenuTitre
            niveau
          }
          ... on Metier_Bloccontenu_BlocsContenus_Paragraphe {
            paragraphe
          }
          ... on Metier_Bloccontenu_BlocsContenus_Image {
            image {
              altText
              sourceUrl
            }
          }
          ... on Metier_Bloccontenu_BlocsContenus_GalerieImages {
            images {
              altText
              sourceUrl
            }
          }
          ... on Metier_Bloccontenu_BlocsContenus_Video {
            idVideo
            miniatureVideo {
              altText
              sourceUrl
            }
          }
          ... on Metier_Bloccontenu_BlocsContenus_Bouton {
            libelleBouton
            typeDeLien
            lienExterne
            lienInterne {
              ... on Page {
                slug
              }
            }
            fichier {
              mediaItemUrl
            }
            icone {
              altText
              sourceUrl(size: LARGE)
              srcSet
            }
          }
          ... on Metier_Bloccontenu_BlocsContenus_Espaceur {
            taille
          }
        }
      }
    }
    actualites {
      nodes {
        actualites {
          global {
            visuelActualite {
              altText
              sourceUrl
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        title
        slug
        date
      }
    }
    page(id: $homePageId) {
      homePage {
        publications {
          titreRubrique
          labelToutesLesPublications
          publications {
            lienPublication {
              ... on Publication {
                id
                slug
                publications {
                  global {
                    couverturePublication {
                      altText
                      sourceUrl
                    }
                  }
                }
                publicationCategories {
                  nodes {
                    id
                    name
                    slug
                    databaseId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
