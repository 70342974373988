import { gql } from "apollo-boost";

export const GET_CAREERS_PAGE_DATA = gql`
  query ($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
      carrieres {
        titreIntro
        contenuIntro
        contenuCandidatureSpontanee
      }
    }
    offres_emploi {
      nodes {
        date
        title
        slug
        offre_emploi {
          typeOffre
        }
      }
    }
  }
`;
