import styled from "styled-components";

export const TimelineItemWrapper = styled.div`
  display: flex;
  padding: 25px;
  ${(props) => props.active && "background-color:rgba(255, 255, 255, 0.7);"}
  ${(props) =>
    props.active &&
    "box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);"}
  color: #043dba;
  cursor: pointer;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  }
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const EventDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  border-left: 2px solid #0043ff;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 10px;
    border-left: 1px solid #0043ff;
  }
`;

export const DetailsContainer = styled.div`
  font-size: 1.34rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
`;

export const EventTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 4px;
  }
`;

export const EventDescription = styled.div`
  font-size: 1.34rem;
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  font-size: 1.5rem;
  font-weight: 300;
  justify-content: center;
  height: fit-content;
  text-align: right;
  padding-right: 20px;
  padding-bottom: 20px;
  text-transform: capitalize;
  b {
    font-size: 2.17rem;
  }
  @media (max-width: 768px) {
    line-height: 1.8rem;
    font-size: 1.2rem;
    max-width: 80px;
    padding: 0;
    padding-right: 10px;
    b {
      font-size: 1.4rem;
    }
  }
`;
