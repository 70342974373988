import { gql } from "apollo-boost";

export const GET_FORMATIONS_PACKAGEES_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
    }
    formations_packagee {
      nodes {
        title
        slug
        id
        formationsPackagees {
          sousTitre
        }
      }
    }
  }
`;
