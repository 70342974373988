import styled from "styled-components";

export const HeroWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  margin-top: 140px;
  &.right {
    text-align: right;
  }
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 33px;
    margin-bottom: 33px;
  }
`;

export const Title = styled.h1`
  max-width: 400px;
  margin-right: -213px;
  padding-right: 40px;
  margin-bottom: 20px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    max-width: 115px;
    margin-right: -28px;
    padding-right: 0px;
    margin-bottom: 5px;
  }
`;

export const BigSubtitle = styled.h1`
  color: #fafbff;
  font-size: 6.7rem;
  font-weight: bold;
  margin-right: -213px;
  @media (max-width: 768px) {
    font-size: 1.81rem;
    margin-right: -28px;
  }
`;

export const ImageContainer = styled.div`
  min-width: 1000px;
  min-height: 450px;
  position: relative;
  margin-bottom: -90px;
  @media (max-width: 768px) {
    width: 70%;
    min-width: 70%;
    min-height: auto;
    margin-bottom: -27px;
  }
`;

export const Image = styled.img`
  width: 1000px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VideoBtnContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 100px;
  position: absolute;
  bottom: 20px;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;

export const VideoLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PlayBtn = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0043ff;
  border: 2px solid #fafbff;
  border-radius: 100px;
  img {
    margin-left: 3px;
  }
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
    border: 1px solid #fafbff;
    img {
      margin-left: 1px;
      width: 5px;
    }
  }
`;

export const Label = styled.span`
  margin-left: 10px;
  color: #fafbff;
  font-size: 1.24rem;
  font-weight: 500;
  @media (max-width: 768px) {
    margin-left: 6px;
    font-size: 0.81rem;
  }
`;
