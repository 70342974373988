import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import TimelineItem from "./TimelineItem";
import { EventsTimelineWrapper } from "./styles/EventsTimelineStyles";

const EventsTimeline = ({ data }) => {
  const { navigateTo } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <EventsTimelineWrapper className="events-timeline">
        {data &&
          data.map(
            (event, i) =>
              i !== 0 && (
                <TimelineItem
                  key={i}
                  data={event.informationsEvenement}
                  onClick={() => {
                    navigateTo("/evenements/" + event.slug);
                  }}
                />
              )
          )}
      </EventsTimelineWrapper>
    </React.Fragment>
  );
};

EventsTimeline.propTypes = {};

EventsTimeline.defaultProps = {};

export default EventsTimeline;
