import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { getDay, getMonth, getYear } from "utils";

import {
  NewsCardWrapper,
  Image,
  DetailsContainer,
  DateContainer,
  DayMonth,
  Sep,
  Year,
  Content,
  Title,
  Excerpt,
} from "./styles/NewsCardStyles";

const NewsCard = ({ showExcerpt, data }) => {
  const { navigateTo, currentLang } = useContext(GlobalContext);
  const { slug, title, date, actualites, categories } = data;
  let category = categories ? categories.nodes[0].slug : "uncategorized";
  let paragraphs = null;
  data.blocContenu &&
    data.blocContenu.blocsContenus &&
    (paragraphs = data.blocContenu.blocsContenus
      .filter((block) => block.paragraphe && true)
      .map((block) => {
        return block.paragraphe;
      }));
  const excerpt =
    paragraphs && paragraphs[0]
      ? paragraphs[0]
          .split("<p>")
          .join("")
          .split("</p>")
          .join("")
          .substr(0, 280) + "..."
      : null;
  const url = currentLang === "en" ? "/news" : "/actualite";
  const day = getDay(date);
  const month = getMonth(date, currentLang === "en" ? "en" : "fr");
  return (
    <React.Fragment>
      <NewsCardWrapper
        className="news-card"
        onClick={() => navigateTo(url + "/" + category + "/" + slug)}
      >
        <Image
          src={actualites.global.visuelActualite.sourceUrl}
          alt={actualites.global.visuelActualite.altText}
        />
        <DetailsContainer>
          <DateContainer>
            <DayMonth>
              {currentLang === "en" ? month : day}
              <Sep />
              {currentLang === "en" ? day : month}
            </DayMonth>
            <Year>{getYear(date)}</Year>
          </DateContainer>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            {/* {showExcerpt && excerpt && (
              <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
            )} */}
          </Content>
        </DetailsContainer>
      </NewsCardWrapper>
    </React.Fragment>
  );
};

NewsCard.propTypes = {
  showExcerpt: PropTypes.bool,
};

NewsCard.defaultProps = {
  showExcerpt: false,
};

export default NewsCard;
