import { gql } from "apollo-boost";

export const GET_NEWSLETTER_DATA = gql`
  {
    paramTresSite {
      parametres {
        newsletter {
          description
          libelleBouton
          messageDeConfirmation
          placeholder
        }
      }
    }
  }
`;
