import styled from "styled-components";

export const TrainingsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 90px 0;
  background-color: #1f1f98;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    padding: 18px 0 14px 0;
  }
`;

export const TrainingsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 40px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 0px;
    margin-bottom: 20px;
  }
`;

export const TrainingsIntro = styled.div`
  padding-top: 30px;
  grid-column: 1 / 3;
  @media (max-width: 768px) {
    grid-column: 1;
    padding-top: 0px;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  @media (max-width: 768px) {
    max-width: 95%;
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  max-width: 340px;
  @media (max-width: 768px) {
    max-width: 110px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.span`
  margin-left: 30px;
  color: #fafbff;
  text-align: justify;
  font-size: 1.82rem;
  p {
    color: #fafbff;
  }
  @media (max-width: 768px) {
    margin-left: 15px;
    p {
      text-align: left;
    }
  }
`;
