import { gql } from "apollo-boost";

export const GET_CONTACT_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
      contact {
        blocSiege {
          adresse
          coordonnees
          titreBloc
          titreItiniraire
          urlItiniraire
        }
        autresContacts {
          titre
          coordonnees
        }
      }
    }
  }
`;
