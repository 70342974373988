import styled from "styled-components";

export const TeamBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 90px 0;
  background-color: #1f1f98;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const IntroWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 35px;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  padding-right: 35px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 0;
  }
`;

export const BiographieContainer = styled.div`
  position: absolute;
  background: #d7e2ff;
  left: 0;
  right: 0;
  min-height: 100%;
  z-index: 1;
  padding: 85px calc(40% + 30px) 85px 65px;
  color: #02203c;
  p {
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 1.67rem;
    font-weight: 400;
  }
  li {
    font-size: 17px;
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    position: relative;
    margin-top: -65px;
    padding: 90px 20px 10px;
    p {
      margin-bottom: 12px;
      line-height: 1.8rem;
      font-size: 1.2rem;
      
    }
    li {
      line-height: 1.8rem;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  }
`;

export const CloseBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  left: 20px;
  transform: rotate(45deg);
  cursor: pointer;
  :before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    border-left: 5px solid #043dba;
  }
  :after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-top: 5px solid #043dba;
  }
  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
    right: 13px;
    top: 60px;
    z-index: 1;
    left: initial;
    :before {
      border-left: 2px solid #043dba;
    }
    :after {
      border-top: 2px solid #043dba;
    }
  }
`;

export const MemberContainer = styled.div`
  width: 40%;
  .member-card {
    position: relative;
    cursor: pointer;
    z-index: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  max-width: 340px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const Description = styled.span`
  margin-left: 30px;
  color: #fafbff;
  font-size: 25px;
  line-height: 3.33rem;
  p {
    color: #fafbff;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
