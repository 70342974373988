import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  PostDetailsWrapper,
  EventCardWrapper,
  ButtonContainer,
  ButtonBlock,
} from "./styles/PostDetailsStyles";
import { extractDate } from "utils";
import { useQuery } from "@apollo/react-hooks";
import { GET_POST_DETAILS_DATA } from "graphql/get_post_details_page_data";
import { GlobalContext } from "GlobalContext";

import AuthorBlock from "components/AuthorBlock";
import EventCard from "components/EventCard";
import ContentBlock from "components/ContentBlock";

import downloadIcon from "images/icone-download.svg";

const PostDetails = (props) => {
  // Initial Values
  let eventCardData = null;
  let contentBlocs = null;
  let author = null;
  let fileUrl = null;
  let seo = null;

  // Consuming Context
  const { activeMegaMenu, isLoading, setIsLoading, setHeroData, currentLang } =
    useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE1" : "cGFnZToyMjI=";

  // GraphQl Queries
  const slug = props.match.params.slug;
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_POST_DETAILS_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug, pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        image: {
          altText:
            pageGqlData.publicationBy.publications.global &&
            pageGqlData.publicationBy.publications.global.visuelPublication
              ? pageGqlData.publicationBy.publications.global.visuelPublication
                  .altText
              : "",
          sourceUrl:
            pageGqlData.publicationBy.publications.global &&
            pageGqlData.publicationBy.publications.global.visuelPublication
              ? pageGqlData.publicationBy.publications.global.visuelPublication
                  .sourceUrl
              : "",
        },
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "Post Details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    let type = "";
    if (
      pageGqlData.publicationBy.publicationCategories &&
      pageGqlData.publicationBy.publicationCategories.nodes[0] &&
      pageGqlData.publicationBy.publicationCategories.nodes[0].children
    ) {
      let pubCat =
        pageGqlData.publicationBy.publicationCategories.nodes[0].name;
      let subCat = "";
      pageGqlData.publicationBy.publicationCategories.nodes[0].children.nodes.map(
        (childCat, i) => {
          if (childCat.publications) {
            childCat.publications.nodes.map((pub, i) => {
              if (pub.slug === slug) subCat = childCat.name;
            });
          }
        }
      );
      type += pubCat;
      if (subCat !== "") type += " > " + subCat;
    }
    eventCardData = {
      titre: pageGqlData.publicationBy.title,
      type: type,
      dateDebut: extractDate(pageGqlData.publicationBy.date),
    };

    contentBlocs = pageGqlData.publicationBy.blocContenu.blocsContenus;
    author = pageGqlData.publicationBy.publications.auteur;
    fileUrl = pageGqlData.publicationBy.publications.global.fichier
      ? pageGqlData.publicationBy.publications.global.fichier.mediaItemUrl
      : null;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <PostDetailsWrapper>
            {eventCardData && eventCardData.titre && (
              <div className="fixed-container">
                <EventCardWrapper>
                  <EventCard data={eventCardData} />
                </EventCardWrapper>
              </div>
            )}

            {(contentBlocs || author) && (
              <div className="fixed-container">
                <div
                  className={`content-container white-bg noHorizontalPad ${
                    (!eventCardData || !eventCardData.titre) && "no-title"
                  }`}
                >
                  {contentBlocs &&
                    contentBlocs.map((block, i) => (
                      <ContentBlock key={i} block={block} />
                    ))}

                  {
                    // Bouton
                    fileUrl && (
                      <div className="container">
                        <ButtonContainer>
                          <ButtonBlock
                            href={fileUrl}
                            target={"_blank"}
                            download
                          >
                            <img
                              src={downloadIcon}
                              alt={
                                currentLang === "en"
                                  ? "télécharger"
                                  : "Download"
                              }
                            />
                            <span>
                              {currentLang === "en"
                                ? "Download the file"
                                : "Télécharger le fichier"}
                            </span>
                          </ButtonBlock>
                        </ButtonContainer>
                      </div>
                    )
                  }
                  {author && author.auteur === "oui" && (
                    <AuthorBlock data={author} />
                  )}
                </div>
              </div>
            )}
          </PostDetailsWrapper>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

PostDetails.propTypes = {};

PostDetails.defaultProps = {};

export default PostDetails;
