import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel, { consts } from "react-elastic-carousel";
import MemberCard from "./MemberCard";
import { isMobile } from "react-device-detect";

import {
  TeamCarouselWrapper,
  CarouselArrows,
} from "./styles/TeamCarouselStyles";
import arrowSvg from "images/arrow.svg";

const TeamCarousel = ({ members }) => {
  const carouselRef = useRef();
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(members.length);
    }
  };
  return (
    <React.Fragment>
      <TeamCarouselWrapper className="team-carousel">
        <Carousel
          infinite
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          itemsToShow={isMobile ? 1 : 4}
          pagination={false}
          renderArrow={({ type, onClick }) => (
            <CarouselArrows onClick={onClick}>
              {type === consts.PREV ? (
                <img src={arrowSvg} alt="Prev" className="prev" />
              ) : (
                  <img src={arrowSvg} alt="Next" className="next" />
                )}
            </CarouselArrows>
          )}
          enableAutoPlay
        >
          {members &&
            members.map((member, i) => (
              <MemberCard key={i} data={member} odd={i % 2 === 0} />
            ))}
        </Carousel>
      </TeamCarouselWrapper>
    </React.Fragment>
  );
};

TeamCarousel.propTypes = {};

TeamCarousel.defaultProps = {};

export default TeamCarousel;
