import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";

import MegaMenu from "components/MegaMenu";
import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Hero from "./Hero";

import { HeaderWrapper } from "./styles/HeaderStyles";

import bg from "images/pattern-hero.png";

const Header = ({ hero }) => {
  const { isLoading, activeMegaMenu, heroData } = useContext(GlobalContext);

  return useMemo(() => {
    return (
      <React.Fragment>
        <HeaderWrapper className="header" bg={bg}>
          <Topbar />
          <Navbar />
          {activeMegaMenu ? (
            <MegaMenu />
          ) : (
            hero && heroData.image.sourceUrl && !isLoading && <Hero />
          )}
        </HeaderWrapper>
      </React.Fragment>
    );
  }, [isLoading, activeMegaMenu, heroData]);
};

Header.propTypes = {
  hero: PropTypes.bool,
};

Header.defaultProps = {
  hero: true,
};

export default Header;
