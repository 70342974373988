import React from "react";
import PropTypes from "prop-types";
import {
  VideoCoverWrapper,
  VideoTitle,
  PlayBtn,
} from "./styles/VideoCoverStyles";

import playBtn from "images/icone-play.svg";

const VideoCover = ({ setShowVideo, bg, title }) => {
  return (
    <React.Fragment>
      <VideoCoverWrapper className="video-cover" bg={bg}>
        <div className="fixed-container">
          {title && <VideoTitle>{title}</VideoTitle>}
          <PlayBtn onClick={() => setShowVideo(true)}>
            <img src={playBtn} alt="Play video" />
          </PlayBtn>
        </div>
      </VideoCoverWrapper>
    </React.Fragment>
  );
};

VideoCover.propTypes = {
  title: PropTypes.string,
};

VideoCover.defaultProps = {
  title: "",
};

export default VideoCover;
