import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { parseDate, getDay, getMonth, getYear } from "utils";
import { isMobile } from "react-device-detect";
import {
  EventCardWrapper,
  EventDataContainer,
  DetailsContainer,
  EventTitle,
  EventDescription,
  RightDataContainer,
  MonthYear,
} from "./styles/EventCardStyles";

const EventCard = ({ data, onClick }) => {
  const {
    dateDebut,
    dateFin,
    lieu,
    theme,
    titre,
    type,
    careerDispoLabel,
    careerDispo,
  } = data;

  const { currentLang } = useContext(GlobalContext);

  return (
    <React.Fragment>
      <EventCardWrapper className="event-card" onClick={onClick}>
        <EventDataContainer>
          {(type || lieu) && (
            <DetailsContainer className="details-container">
              {type && (
                <span style={{ textTransform: "capitalize" }}>{type}</span>
              )}
              {!isMobile && type && <span> </span>}
              {!isMobile && lieu && <span>{"| "}</span>}
              {lieu && (
                <b
                  className="place"
                  dangerouslySetInnerHTML={{ __html: lieu }}
                />
              )}
            </DetailsContainer>
          )}
          {titre && (
            <EventTitle
              className="title"
              dangerouslySetInnerHTML={{ __html: titre }}
            />
          )}
          {theme && (
            <EventDescription dangerouslySetInnerHTML={{ __html: theme }} />
          )}
        </EventDataContainer>
        {dateDebut && (
          <RightDataContainer>
            <b>
              {getDay(parseDate(dateDebut))}
              {dateFin && <span> - {getDay(parseDate(dateFin))}</span>}
            </b>
            <MonthYear>
              <span>
                {getMonth(
                  parseDate(dateDebut),
                  currentLang === "en" ? "en" : "fr"
                )}
              </span>
              <span>
                {isMobile && <span>{" . "}</span>}{" "}
                {getYear(parseDate(dateDebut))}
              </span>
            </MonthYear>
          </RightDataContainer>
        )}
        {careerDispo && (
          <RightDataContainer>
            <span className="small">{careerDispoLabel}</span>
            <b className="small">{careerDispo}</b>
          </RightDataContainer>
        )}
      </EventCardWrapper>
    </React.Fragment>
  );
};

EventCard.propTypes = {};

EventCard.defaultProps = {};

export default EventCard;
