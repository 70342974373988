import styled from "styled-components";

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const LinkContainer = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    transform: translateY(-1px);
  }
`;

export const Label = styled.span`
  color: #0043ff;
  font-size: 1.34rem;
  font-weight: 500;
  &.white {
    color: #fafbff;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem
  }
`;

export const Arrow = styled.img`
  width: 30px;
  margin-left: 10px;
  @media (max-width: 768px) {
    width: 15px;
    margin-left: 6px;
  }
`;
