import { gql } from "apollo-boost";

export const GET_NAVBAR_DATA = gql`
  query($mainMenuId: ID!, $footerMenuId: ID!) {
    main_menu: menu(id: $mainMenuId) {
      menuItems {
        nodes {
          label
          id
          url
          childItems {
            nodes {
              label
              id
              url
            }
          }
        }
      }
    }
    paramTresSite {
      parametres {
        elementsGraphiques {
          logoIaBlanc {
            altText
            sourceUrl
          }
          logoIaBleu {
            altText
            sourceUrl
          }
        }
      }
    }
    menu(id: $footerMenuId) {
      menuItems {
        nodes {
          label
        }
      }
    }
  }
`;
