import Home from "pages/Home";
import News from "pages/News";
import NewsDetails from "pages/NewsDetails";
import TrainingBespoke from "pages/TrainingBespoke";
import TrainingPackaged from "pages/TrainingPackaged";
import TrainingPackagedDetails from "pages/TrainingPackagedDetails";
import MetierDetails from "pages/MetierDetails";
import InstitutAmadeus from "pages/InstitutAmadeus";
import Posts from "pages/Posts";
import PostDetails from "pages/PostDetails";
import Careers from "pages/Careers";
import CareerDetails from "pages/CareerDetails";
import Events from "pages/Events";
import EventDetails from "pages/EventDetails";
import Contact from "pages/Contact";
import Mentions from "pages/Mentions";
import PressList from "pages/PressList";

const routes = [
  { exact: true, path: "/", component: Home },
  { exact: true, path: "/actualite/:category?", component: News },
  { exact: true, path: "/news/:category?", component: News },
  { path: "/actualite/:category/:slug", component: NewsDetails },
  { path: "/news/:category/:slug", component: NewsDetails },
  {
    exact: true,
    path: "/formations/formations-sur-mesure",
    component: TrainingBespoke,
  },
  {
    exact: true,
    path: "/formations/formations-packagees",
    component: TrainingPackaged,
  },
  {
    path: "/formations/formations-packagees/:slug",
    component: TrainingPackagedDetails,
  },
  {
    exact: true,
    path: "/trainings/customized-trainings",
    component: TrainingBespoke,
  },
  {
    exact: true,
    path: "/trainings/packaged-trainings",
    component: TrainingPackaged,
  },
  {
    path: "/trainings/packaged-trainings/:slug",
    component: TrainingPackagedDetails,
  },
  { path: "/metier/:slug", component: MetierDetails },
  { exact: true, path: "/institut-amadeus", component: InstitutAmadeus },
  { exact: true, path: "/amadeus-institute", component: InstitutAmadeus },
  { exact: true, path: "/publications/:category?", component: Posts },
  { path: "/publications/:category/:slug", component: PostDetails },
  { exact: true, path: "/carrieres", component: Careers },
  { exact: true, path: "/careers", component: Careers },
  { path: "/carrieres/:slug", component: CareerDetails },
  { path: "/careers/:slug", component: CareerDetails },
  { exact: true, path: "/evenements", component: Events },
  { path: "/evenements/:slug", component: EventDetails },
  { exact: true, path: "/mentions-legales", component: Mentions },
  { exact: true, path: "/legal-notice", component: Mentions },
  { exact: true, path: "/contact", component: Contact },
  { exact: true, path: "/presse", component: PressList },
];

export default routes;
