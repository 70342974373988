import { gql } from "apollo-boost";

export const GET_FORMATIONS_SUR_MESURE_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
      formationsSurMesure {
        introduction
        phases {
          titre1
          titre2
          contenu
          icone {
            altText
            sourceUrl
          }
        }
        blocOffresDeFormations {
          afficherLeBloc
          titreRubrique
          description
          labelToutesLesFormations
          formations {
            formation {
              ... on Formation_packagee {
                slug
                id
                title
              }
            }
          }
          imageDarrierePlan {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;
