import styled from "styled-components";

export const MetiersBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f5ff;
  padding-top: 200px;
  padding-bottom: 140px;
  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    :before {
      width: calc(50% - 30px) !important;
      left: -20px !important;
      border: 1px solid #043dba !important;
    }
    :after {
      width: calc(50% - 30px) !important;
      right: -20px !important;
      border: 1px solid #043dba !important;
      content: "";
      height: 2px;
      position: absolute;
      bottom: 10px;
    }
  }
`;

export const MetiersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
`;
