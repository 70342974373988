import styled from "styled-components";

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  flex: 1;
  color: #043dba;
  @media (max-width: 768px) {
    align-items: start;
    text-align: left;
  }
`;

export const Title = styled.div`
  font-size: 2.25rem;
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 1.09rem;
    max-width: 265px;
  }
`;

export const SubTitle = styled.div`
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  max-width: 200px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 2px;
  }
`;

export const MoreLinkContainer = styled.div`
  margin-top: 8px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  @media (max-width: 768px) {
    margin-top: 0;
    width: 13px;
    height: 13px;
    align-self: center;
  }
`;

export const MoreLink = styled.a`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s;
  :before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    border-left: 1px solid #043dba;
  }
  :after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-top: 1px solid #043dba;
  }
  @media (max-width: 768px) {
    width: 13px;
    height: 13px;
  }
`;

export const TrainingCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: ${(props) => (props.odd ? "#D7E2FF" : "#ebf0ff")};
  height: 300px;
  padding: 20px 30px;
  padding-top: 110px;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #02c5ff;
    ${DataContainer} {
      color: #151686;
    }
    ${MoreLink} {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-left: 15px;
    flex-direction: row;
    height: auto;
    padding: 13px 20px;
  }
`;
