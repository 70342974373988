import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { EventsWrapper, EventCover } from "./styles/HomeStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_HOME_PAGE_DATA } from "graphql/get_home_page_data";
import { GlobalContext } from "GlobalContext";

import NewsBlock from "components/NewsBlock";
import EventCard from "components/EventCard";
import EventsTimeline from "components/EventsTimeline";
import AboutBlock from "components/AboutBlock";
import MetiersBlock from "components/MetiersBlock";
import TrainingsBlock from "components/TrainingsBlock";
import PostsBlock from "components/PostsBlock";
import RecentPostsBlock from "components/RecentPostsBlock";

const Home = (props) => {
  // Initial Values
  let pageData = null;
  let newsData = null;
  let aboutData = null;
  let eventsData = null;
  let metiersData = null;
  let formationsData = null;
  let publicationsData = null;
  let publicationsList = null;
  let seo = null;

  // Consuming Context
  const {
    navigateTo,
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
  } = useContext(GlobalContext);

  // GraphQl Queries
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_HOME_PAGE_DATA, {
    fetchPolicy: "no-cache",
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageData.hero.titre1,
        titre2: pageData.hero.titre2,
        image: {
          altText: pageData.hero.miniatureVideo.altText,
          sourceUrl: pageData.hero.miniatureVideo.sourceUrl,
        },
        labelLectureVideo: pageData.hero.labelLectureVideo,
        idVideo: pageData.hero.idVideo,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log("Home page loading data error: ", pageGqlError.message);
  }, [pageGqlError]);

  if (pageGqlData) {
    pageData = pageGqlData.page.homePage;
    newsData = {
      title: pageData.actualites.titreRubrique,
      moreLinkLabel: pageData.actualites.labelToutesLesActualites,
      moreLink: pageData.actualites.lienToutesLesActualites,
      news: pageGqlData.actualites.nodes,
    };
    aboutData = pageData.institutAmadeus;
    eventsData = [];
    pageData &&
      pageData.evenements &&
      pageData.evenements.evenement &&
      pageData.evenements.evenement.map((event) => {
        eventsData.push(event.evenement);
      });

    metiersData = pageData.metiers;
    formationsData = pageData.formations;
    publicationsData = pageData.publications;
    publicationsList = pageGqlData.publications.nodes;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <React.Fragment>
            <div className="main-container">
              {newsData && <NewsBlock newsData={newsData} />}
              {publicationsList && (
                <PostsBlock publicationsList={publicationsList} />
              )}
              {/* {eventsData && (
                <EventsWrapper>
                  <div className="fixed-container">
                    <h2 className="colored_bg">{eventsData.titreRubrique}</h2>
                  </div>
                  <EventCover
                    bg={
                      eventsData[0].informationsEvenement.visuelEvenement
                        .sourceUrl
                    }
                  />
                  <div className="fixed-container">
                    <EventCard
                      data={eventsData[0].informationsEvenement}
                      onClick={() =>
                        navigateTo("/evenements/" + eventsData[0].slug)
                      }
                    />
                    <EventsTimeline data={eventsData} />
                  </div>
                </EventsWrapper>
              )} */}
              {aboutData && <AboutBlock aboutData={aboutData} />}

              {metiersData && <MetiersBlock metiersData={metiersData} />}

              {formationsData && (
                <TrainingsBlock formationsData={formationsData} />
              )}

              {publicationsData && (
                <RecentPostsBlock publicationsData={publicationsData} />
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, isLoading, activeMegaMenu]);
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
