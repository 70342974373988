import styled from "styled-components";

export const GContainer = styled.div`
  @media (max-width: 768px) {
    background-color: #f4f3ff;
  }
`;
export const AboutBlockWrapper = styled.div`
  background-color: #1f1f98;
  padding: 100px 0 100px 150px;
  position: relative;
  @media (max-width: 768px) {
    padding: 26px 20px;
  }
`;

export const ImageContainer = styled.div`
  min-width: 1200px;
  min-height: 585px;
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    min-height: auto;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const InfosContainer = styled.div`
  background-color: #fafbff;
  padding: 60px 65px;
  max-width: 600px;
  position: absolute;
  right: 105px;
  bottom: -100px;
  @media (max-width: 768px) {
    background-color: #ffffff;
    max-width: 88%;
    width: 88%;
    right: initial;
    top: initial;
    padding: 24px 20px;
    position: relative;
    float: left;
    margin-top: -84px;
    margin-left: 6%;
    bottom: initial;
  }
`;

export const Title = styled.h2`
  width: 220px;
  margin-bottom: 55px;
  line-height: 4.41rem;
  @media (max-width: 768px) {
    width: 69px;
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
`;

export const Content = styled.div`
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const Slogan = styled.div`
  color: #043dba;
  font-size: 3.75rem;
  line-height: 4.41rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  p {
    color: #043dba;
    font-size: 3.75rem;
    line-height: 4.41rem;
  }
  @media (max-width: 768px) {
    font-size: 1.45rem;
    line-height: 1.58rem;
    p {
      font-size: 1.45rem;
      line-height: 1.58rem;
    }
  }
`;

export const Clear = styled.div`
  clear: both;
  height: 0;
`;
