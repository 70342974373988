import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";
import { getDay, getMonth, getYear } from "utils";

import {
  NewsSummaryCardWrapper,
  DetailsContainer,
  DateContainer,
  DayMonth,
  Sep,
  Year,
  Content,
  Title,
} from "./styles/NewsSummaryCardStyles";

const NewsSummaryCard = ({ data }) => {
  const { navigateTo, currentLang } = useContext(GlobalContext);
  const { slug, title, date, categories } = data;
  let category = categories ? categories.nodes[0].slug : "uncategorized";
  const url = currentLang === "en" ? "/news" : "/actualite";
  const day = getDay(date);
  const month = getMonth(date, currentLang === "en" ? "en" : "fr");
  return (
    <React.Fragment>
      <NewsSummaryCardWrapper
        className="news-summary-card"
        onClick={() => navigateTo(url + "/" + category + "/" + slug)}
      >
        <DetailsContainer>
          <DateContainer>
            <DayMonth>
              {currentLang === "en" ? month : day}
              <Sep />
              {currentLang === "en" ? day : month}
            </DayMonth>
            <Year>{getYear(date)}</Year>
          </DateContainer>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </Content>
        </DetailsContainer>
      </NewsSummaryCardWrapper>
    </React.Fragment>
  );
};

NewsSummaryCard.propTypes = {};

NewsSummaryCard.defaultProps = {};

export default NewsSummaryCard;
