import styled from "styled-components";

export const TrainingPackagedWrapper = styled.div`
  .formations-block {
    margin-top: 200px;
  }
`;

export const TrainigsList = styled.div`
  margin-top: 120px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @media (max-width: 768px) {
    margin-top: 40px;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 0px;
    margin-bottom: 0;
    .formation-card {
      margin-left: 0;
    }
  }
`;
