import styled from "styled-components";

export const RecentPostsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f5ff;
  padding-top: 110px;
  @media (max-width: 768px) {
    padding-top: 64px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 50px;
  max-width: 340px;
  @media (max-width: 768px) {
    margin-bottom: 35px;
  }
`;

export const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    grid-column-gap: 10px;
    margin-bottom: 20px;
  }
`;

export const Poster = styled.img`
  width: 100%;
  cursor: pointer;
`;
