import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  TrainingModuleWrapper,
  InfosContainer,
  Label,
  Title,
  Content,
} from "./styles/TrainingModuleStyles";

import { GlobalContext } from "GlobalContext";

const TrainingModule = ({ data, odd, number }) => {
  const { titreModule, detailModule } = data;
  // Consuming Context
  const { currentLang } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <TrainingModuleWrapper odd={odd} className="training-module-item">
        <InfosContainer>
          <Label>
            {currentLang === "en" ? "Unit" : "Module"} {number + 1}
          </Label>
          <Title dangerouslySetInnerHTML={{ __html: titreModule }} />
          {detailModule && (
            <Content dangerouslySetInnerHTML={{ __html: detailModule }} />
          )}
        </InfosContainer>
      </TrainingModuleWrapper>
    </React.Fragment>
  );
};

TrainingModule.propTypes = {};

TrainingModule.defaultProps = {};

export default TrainingModule;
