import styled from "styled-components";

export const VideoCoverWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #0043ff;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 200px 120px;
  min-height: 570px;
  div {
    display: flex;
  }
  @media (max-width: 768px) {
    padding: 0;
    min-height: 360px;
    .fixed-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
`;

export const VideoTitle = styled.div`
  color: #ffffff;
  font-size: 3.75rem;
  font-weight: bold;
  max-width: 70%;
  line-height: 4.5rem;
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const PlayBtn = styled.a`
  display: block;
  margin: auto;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
    img {
      width: 50px;
    }
  }
`;
