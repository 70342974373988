import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "GlobalContext";

import Link from "ui/Link";

import {
  MetierCardWrapper,
  DataContainer,
  IconContainer,
  Icon,
  Title,
} from "./styles/MetierCardStyles";

const MetierCard = ({ data }) => {
  const { navigateTo } = useContext(GlobalContext);
  const [icon, setIcon] = useState(null);
  const { title, slug, visuelsMetier } = data;
  let normalIcon = null;
  let hoverIcon = null;
  if (visuelsMetier && visuelsMetier.iconesMetier) {
    visuelsMetier.iconesMetier.iconeNormale &&
      (normalIcon = (
        <Icon
          src={visuelsMetier.iconesMetier.iconeNormale.sourceUrl}
          alt={visuelsMetier.iconesMetier.iconeNormale.altText}
        />
      ));
    visuelsMetier.iconesMetier.iconeSurvol &&
      (hoverIcon = (
        <Icon
          src={visuelsMetier.iconesMetier.iconeSurvol.sourceUrl}
          alt={visuelsMetier.iconesMetier.iconeSurvol.altText}
        />
      ));
  }

  useEffect(() => {
    setIcon(normalIcon);
  }, []);

  const changeIcon = (type) => {
    type === "over" ? setIcon(hoverIcon) : setIcon(normalIcon);
  };

  return (
    <React.Fragment>
      <MetierCardWrapper
        className="metier-card"
        onMouseOver={() => changeIcon("over")}
        onMouseOut={() => changeIcon("out")}
        onClick={() => navigateTo("/metier/" + slug)}
      >
        <DataContainer>
          <IconContainer>{icon}</IconContainer>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </DataContainer>
        <Link color="white" />
      </MetierCardWrapper>
    </React.Fragment>
  );
};

MetierCard.propTypes = {};

MetierCard.defaultProps = {};

export default MetierCard;
