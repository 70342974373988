import styled from "styled-components";

export const ContentWrapper = styled.div`
  color: #02203c;
  font-size: 1.6rem;
  line-height: 2.33rem;
  font-weight: 400;
  @media (max-width: 768px) {
    line-height: 1.8rem;
      font-size: 1.2rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #043dba;
    text-transform: initial;
    font-weight: 400;
    margin: 0;
  }
  h2 {
    margin-bottom: 22px;
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 2.17rem;
    margin-bottom: 18px;
  }
  p {
    margin: 0;
    margin-bottom: 25px;
    :last-child {
      margin-bottom: 0;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  b,
  strong {
    font-weight: 500;
  }
  .wysiwyg {
    a {
      color: #0043ff;
      text-decoration: none;
      cursor: pointer;
      font-size: 1.82rem;
      :hover {
        color: #02c5ff;
      }
    }
    ul {
      list-style: none;
      padding-left: 28px;
      margin: 0;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        padding-left: 10px;
      }
      li {
        display: block;
        line-height: 2.66rem;
        padding: 8px 0;
        padding-left: 22px;
        position: relative;
        :before {
          content: "";
          width: 12px;
          height: 12px;
          background: rgba(0, 67, 255, 0.3);
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 3px;
          border-radius: 100px;
          position: absolute;
          top: 18px;
          left: 0;
        }
        :after {
          content: "";
          width: 8px;
          height: 8px;
          background: #0043ff;
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 3px;
          border-radius: 100px;
          position: absolute;
          top: 20px;
          left: 2px;
        }
        @media (max-width: 768px) {
          line-height: 1.8rem;
          padding: 5px 0;
          padding-left: 22px;
          :before {
            top: .7rem;
          }
          :after {  
            top: .85rem;
          }
        }
      }
      ul {
        margin-top: 15px;
        margin-bottom: 0;
        padding-left: 0;
        li {
          font-size: 1.5rem;
          padding: 2px 0;
          padding-left: 20px;
          position: relative;
          :before {
            content: "••";
            color: rgba(0, 67, 255, 0.3);
            width: 10px;
            height: 32px;
            background: transparent;
            position: absolute;
            top: 2px;
            left: 0;
          }
          :after {
            display: none;
          }
        }
      }
    }
    ol {
      list-style: none;
      padding-left: 20px;
      margin: 0;
      margin-bottom: 15px;
      li {
        display: flex;
        align-items: center;
        line-height: 2.66rem;
        padding: 8px 0;
        counter-increment: li;
        :before {
          content: "." counter(li);
          color: #0043ff;
          display: block;
          margin-right: 10px;
          text-align: right;
          direction: rtl;
          width: 20px;
        }
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
`;

export const Space = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) =>
    props.size === "large"
      ? "90px"
      : props.size === "medium"
        ? "60px"
        : "30px"};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonBlock = styled.a`
  background-color: #1f1f98;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  padding: 35px 65px;
  color: #fafbff;
  font-size: 2.5rem;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`;
