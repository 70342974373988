import React, { useState } from "react";
import PropTypes from "prop-types";
import TeamCarousel from "components/TeamCarousel";
import MemberCard from "components/TeamCarousel/MemberCard";

import {
  TeamBlockWrapper,
  IntroWrapper,
  IntroContainer,
  BiographieContainer,
  CloseBtn,
  MemberContainer,
  Title,
  Description,
} from "./styles/TeamBlockStyles";

import bg from "images/team-block-bg.jpg";

const TeamBlock = ({ data }) => {
  const [bioOpened, setBioOpened] = useState(false);
  const { titreRubrique, introduction, bff, membres } = data;
  return (
    <React.Fragment>
      <TeamBlockWrapper className="formations-block" bg={bg}>
        <div className="fixed-container">
          <IntroWrapper>
            <IntroContainer>
              <Title
                className="secondary"
                dangerouslySetInnerHTML={{ __html: titreRubrique }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: introduction,
                }}
              />
            </IntroContainer>
            <MemberContainer>
              {bioOpened && (
                <BiographieContainer>
                  <CloseBtn onClick={() => setBioOpened(false)} />
                  {bff && bff.biographie && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: bff.biographie,
                      }}
                    />
                  )}
                </BiographieContainer>
              )}
              <MemberCard
                featured
                data={bff}
                onClick={() => setBioOpened(true)}
              />
            </MemberContainer>
          </IntroWrapper>
        </div>
        <TeamCarousel members={membres} />
      </TeamBlockWrapper>
    </React.Fragment>
  );
};

TeamBlock.propTypes = {};

TeamBlock.defaultProps = {};

export default TeamBlock;
