import React from "react";
import PropTypes from "prop-types";
import {
  EventProgramWrapper,
  InfosContainer,
  Time,
  Title,
  Content,
  ModeratorsContainer,
  ModeratorName,
  ModeratorProfession,
  SpeakersContainer,
  SpeakersSectionTitle,
  SpeakerInfos,
  SpeakerName,
  SpeakerProfession,
} from "./styles/EventProgramStyles";

const EventProgram = ({ data, odd }) => {
  const {
    heureDebut,
    heureFin,
    titre,
    contenu,
    moderateur,
    nomPrenomModerateur,
    fonctionOrganismeModerateur,
    intervenants,
    titreIntervenants,
    intervenant,
  } = data;
  return (
    <React.Fragment>
      <EventProgramWrapper odd={odd} className="event-program-item">
        <InfosContainer>
          {heureDebut && heureFin && (
            <Time>
              {heureDebut} – {heureFin}
            </Time>
          )}
          <Title dangerouslySetInnerHTML={{ __html: titre }} />
          {contenu && <Content dangerouslySetInnerHTML={{ __html: contenu }} />}
        </InfosContainer>
        {moderateur === "oui" && (
          <ModeratorsContainer>
            <ModeratorName>Moderator: {nomPrenomModerateur} ••</ModeratorName>
            <ModeratorProfession>
              {fonctionOrganismeModerateur}
            </ModeratorProfession>
          </ModeratorsContainer>
        )}
        {intervenants === "oui" && intervenant && (
          <SpeakersContainer>
            <SpeakersSectionTitle>{titreIntervenants}</SpeakersSectionTitle>
            {intervenant.map((speaker, i) => (
              <SpeakerInfos key={i}>
                <SpeakerName>{speaker.nomPrenomSpeaker}</SpeakerName>
                <SpeakerProfession
                  dangerouslySetInnerHTML={{
                    __html: speaker.fonctionOrganismeSpeaker,
                  }}
                />
              </SpeakerInfos>
            ))}
          </SpeakersContainer>
        )}
      </EventProgramWrapper>
    </React.Fragment>
  );
};

EventProgram.propTypes = {};

EventProgram.defaultProps = {};

export default EventProgram;
