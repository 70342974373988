import { gql } from "apollo-boost";

export const GET_IA_PAGE_DATA = gql`
  query($pageId: ID!) {
    page(id: $pageId) {
      title
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        focuskw
      }
      heroPage {
        titre
        sousTitre
        introduction
        imageHero {
          altText
          sourceUrl
        }
      }
      institutAmadeus {
        presentationIa {
          titre
          contenu
          signature
        }
        video {
          titreVideo
          idVideo
          miniatureVideo {
            altText
            sourceUrl
          }
        }
        auFilDuTemps {
          titreRubrique
          introduction
          contenuFinal
          faitsMarquants {
            date
            titre
            contenu
            visuel {
              altText
              sourceUrl
            }
          }
        }
        equipe {
          titreRubrique
          introduction
          bff {
            nomEtPrenom
            fonction1
            fonction2
            biographie
            image {
              altText
              sourceUrl
            }
          }
          membres {
            nomEtPrenom
            fonction1
            fonction2
            image {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
