import { gql } from "apollo-boost";

export const GET_MEGAMENU_DATA = gql`
  query($megaMenuId: ID!) {
    mega_menu: menu(id: $megaMenuId) {
      menuItems {
        nodes {
          label
          id
          url
          childItems {
            nodes {
              label
              id
              url
            }
          }
        }
      }
    }
    paramTresSite {
      parametres {
        elementsGraphiques {
          visuelMegamenu {
            sourceUrl
          }
        }
      }
    }
  }
`;
