import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  NewsDetailsWrapper,
  EventCardWrapper,
} from "./styles/NewsDetailsStyles";
import { extractDate } from "utils";
import { useQuery } from "@apollo/react-hooks";
import { GET_NEWS_DETAILS_DATA } from "graphql/get_news_details_page_data";
import { GlobalContext } from "GlobalContext";

import EventCard from "components/EventCard";
import ContentBlock from "components/ContentBlock";

const NewsDetails = (props) => {
  // Initial Values
  let eventCardData = null;
  let contentBlocs = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  const pageId = currentLang === "en" ? "cGFnZTozMzE5" : "cGFnZToyNDI=";

  // GraphQl Queries
  const slug = props.match.params.slug;
  const category = props.match.params.category;
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_NEWS_DETAILS_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug, pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        image: {
          altText:
            pageGqlData.actualiteBy.actualites.global.visuelActualite.altText,
          sourceUrl:
            pageGqlData.actualiteBy.actualites.global.visuelActualite.sourceUrl,
        },
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "News Details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    eventCardData = {
      type: category,
      titre: pageGqlData.actualiteBy.title,
      dateDebut: extractDate(pageGqlData.actualiteBy.date),
    };

    contentBlocs = pageGqlData.actualiteBy.blocContenu.blocsContenus;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <NewsDetailsWrapper>
            {eventCardData && eventCardData.titre && (
              <div className="fixed-container">
                <EventCardWrapper>
                  <EventCard data={eventCardData} />
                </EventCardWrapper>
              </div>
            )}

            {contentBlocs && (
              <div className="fixed-container">
                <div
                  className={`content-container white-bg noHorizontalPad ${
                    (!eventCardData || !eventCardData.titre) && "no-title"
                  }`}
                >
                  {contentBlocs &&
                    contentBlocs.map((block, i) => (
                      <ContentBlock key={i} block={block} />
                    ))}
                </div>
              </div>
            )}
          </NewsDetailsWrapper>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

NewsDetails.propTypes = {};

NewsDetails.defaultProps = {};

export default NewsDetails;
