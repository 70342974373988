import React from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import Pages from "pages";

import "normalize.css";
import GlobalStyles from "./GlobalStyles";
import GlobalContext from "./GlobalContext";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

function App() {
  return (
    <Router>
      <Route path="/:lang?" component={Main} />
    </Router>
  );
}

function Main() {
  const { lang } = useParams();
  const langPrefix = lang && lang === "en" ? "/en" : "";
  const client = new ApolloClient({
    uri: "https://admin.amadeusonline.org" + langPrefix + "/index.php?graphql",
  });
  return (
    <ApolloProvider client={client}>
      <GlobalContext>
        <GlobalStyles />
        <div className="App">
          <Pages />
        </div>
      </GlobalContext>
    </ApolloProvider>
  );
}

export default App;
