import styled from "styled-components";

export const AuthorBlockWrapper = styled.div`
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
  }
`;

export const ImageContainer = styled.div`
  max-width: 165px;
  padding-top: 20px;
  border-top: 6px solid #043DBA;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0;
  @media (max-width: 768px) {
    padding: 15px 15px 0;
  }
`;

export const Name = styled.div`
  color: #043DBA;
  font-size: 2.08rem;
  font-weight: bold;
  line-height: 2.41rem;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export const Bio = styled.div`
  color: #02203C;
  font-size: 1.33rem;
  line-height: 2.16rem;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
