import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import { GlobalContext } from "GlobalContext";
import { ErrorMessage } from "./styles/NotFoundStyles";

const NotFound = (props) => {
  // Consuming Context
  const { activeMegaMenu, setHeroData } = useContext(GlobalContext);

  useEffect(() => {
    setHeroData({
      titre1: null,
      titre2: null,
      labelLectureVideo: null,
      idVideo: null,
      image: {
        altText: null,
        sourceUrl: null,
      },
      miniatureVideo: {
        altText: null,
        sourceUrl: null,
      },
      loading: false,
      error: null,
    });
  }, []);

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle="Page non trouvée" metaDesc="404 Page non trouvée" />
        {!activeMegaMenu && (
          <React.Fragment>
            <div className="fixed-container">
              <div className="container">
                <ErrorMessage>404 Page non trouvée</ErrorMessage>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }, [activeMegaMenu]);
};

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
