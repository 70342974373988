import styled from "styled-components";

export const NewsDetailsWrapper = styled.div`
  .content-block {
    @media (max-width: 768px) {
      p{
        margin-bottom: 15px;
      }
    }
  }
`;

export const EventCardWrapper = styled.div`
  .event-card {
    position: relative;
    background-color: rgba(0, 67, 255, 0.95);
    align-items: flex-end;
    .title {
      color: #fafbff;
      font-size: 2.5rem;
      font-weight: 500;
      text-transform: initial;
      margin-right: 50px;
    }
    @media (max-width: 768px) {
      .title {
        font-size: 1.3rem;
        line-height: 1.8rem;
        margin-right: 0;
        margin-top: 50px;
        margin-bottom: 0;
      }
    }
  }
`;
