import React from "react";
import PropTypes from "prop-types";
import { LinkWrapper, LinkContainer, Label, Arrow } from "./styles/LinkStyles";

import arrowSVGblue from "images/fleche-lire-plus.svg";
import arrowSVGwhite from "images/fleche-lire-plus-white.svg";

const Link = ({ label, color, onClick }) => {
  return (
    <React.Fragment>
      <LinkWrapper className="link">
        <LinkContainer onClick={onClick}>
          {label && (
            <Label className={color === "white" && "white"}>{label}</Label>
          )}
          <Arrow
            src={color === "white" ? arrowSVGwhite : arrowSVGblue}
            alt="fleche-lire-plus"
          />
        </LinkContainer>
      </LinkWrapper>
    </React.Fragment>
  );
};

Link.propTypes = {
  onClick: PropTypes.func,
};

Link.defaultProps = {
  onClick: () => {},
};

export default Link;
