import React, { useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Seo from "components/Seo.jsx";
import {
  CareerDetailsWrapper,
  EventCardWrapper,
  CtaWrapper,
  Text,
  OfferContent,
} from "./styles/CareerDetailsStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_CAREER_DETAILS_PAGE_DATA } from "graphql/get_career_details_page_data";
import { GlobalContext } from "GlobalContext";

import EventCard from "components/EventCard";

const CareerDetails = (props) => {
  // Initial Values
  let eventCardData = null;
  let offre_details = null;
  let contact_infos = null;
  let seo = null;

  // Consuming Context
  const {
    activeMegaMenu,
    isLoading,
    setIsLoading,
    setHeroData,
    currentLang,
  } = useContext(GlobalContext);

  // GraphQl Queries
  const slug = props.match.params.slug;
  const pageId = currentLang === "en" ? "cGFnZTozMzE2" : "cGFnZToyMjQ=";
  const {
    loading: pageGqlLoading,
    error: pageGqlError,
    data: pageGqlData,
  } = useQuery(GET_CAREER_DETAILS_PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: { slug, pageId },
  });

  // Setting Data
  useEffect(() => {
    setIsLoading(pageGqlLoading);
    if (pageGqlData) {
      setHeroData({
        titre1: pageGqlData.page.heroPage.titre,
        titre2: pageGqlData.page.heroPage.sousTitre,
        image: {
          altText: pageGqlData.page.heroPage.imageHero.altText,
          sourceUrl: pageGqlData.page.heroPage.imageHero.sourceUrl,
        },
        labelLectureVideo: null,
        idVideo: null,
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: false,
        error: null,
      });
    } else {
      setHeroData({
        titre1: null,
        titre2: null,
        labelLectureVideo: null,
        idVideo: null,
        image: {
          altText: null,
          sourceUrl: null,
        },
        miniatureVideo: {
          altText: null,
          sourceUrl: null,
        },
        loading: pageGqlLoading,
        error: pageGqlError,
      });
    }
  }, [pageGqlLoading]);

  useEffect(() => {
    pageGqlError &&
      console.log(
        "Career Details page loading data error: ",
        pageGqlError.message
      );
  }, [pageGqlError]);

  if (pageGqlData) {
    const oEmp = pageGqlData.offre_emploiBy;
    eventCardData = {
      titre: oEmp ? oEmp.title : null,
      type: oEmp
        ? oEmp.offre_emploi.typeOffre === "emploi"
          ? "Offre d'emploi"
          : "Offre de stage"
        : null,
      lieu:
        oEmp && oEmp.offre_emploi.typeOffre === "emploi"
          ? oEmp.offre_emploi.typeContrat.toUpperCase()
          : null,
    };
    offre_details = oEmp ? oEmp.offre_emploi.detailsOffre : null;
    contact_infos = oEmp ? oEmp.offre_emploi.blocContact : null;
    seo = pageGqlData.page.seo;
  }

  return useMemo(() => {
    return (
      <React.Fragment>
        <Seo metaTitle={seo && seo.title} metaDesc={seo && seo.metaDesc} />
        {!activeMegaMenu && !isLoading && (
          <CareerDetailsWrapper>
            {eventCardData && eventCardData.titre && (
              <div className="fixed-container">
                <EventCardWrapper>
                  <EventCard data={eventCardData} />
                </EventCardWrapper>
              </div>
            )}

            <div className="fixed-container">
              <div
                className={`content-container white-bg noHorizontalPad ${
                  (!eventCardData || !eventCardData.titre) && "no-title"
                }`}
              >
                <div className="container">
                  {offre_details &&
                    offre_details.map((block, i) => (
                      <OfferContent key={i}>
                        <h2>{block.titre}</h2>
                        <div
                          dangerouslySetInnerHTML={{ __html: block.contenu }}
                        />
                      </OfferContent>
                    ))}
                </div>
              </div>
            </div>
            {contact_infos && (
              <CtaWrapper>
                <div className="fixed-container">
                  <Text>
                    <div dangerouslySetInnerHTML={{ __html: contact_infos }} />
                  </Text>
                </div>
              </CtaWrapper>
            )}
          </CareerDetailsWrapper>
        )}
      </React.Fragment>
    );
  }, [pageGqlData, activeMegaMenu, isLoading]);
};

CareerDetails.propTypes = {};

CareerDetails.defaultProps = {};

export default CareerDetails;
