import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { isMobile } from "react-device-detect";

import arrowSvg from "images/arrow.svg";

import {
  HistoryCarouselWrapper,
  CarouselItem,
  ImageConatiner,
  ContentContainer,
  Date,
  Intro,
  Text,
  DayMonth,
  Year,
} from "./styles/HistoryCarouselStyles";

const HistoryCarousel = ({ items, showFullDate }) => {
  const sortedItems = items
    ? items.sort(
      (a, b) =>
        moment(b.date).format("YYYYMMDD") - moment(a.date).format("YYYYMMDD")
    )
    : null;
  return (
    <React.Fragment>
      <HistoryCarouselWrapper arrow={arrowSvg}>
        <Carousel
          centerMode
          swipeable
          infiniteLoop
          centerSlidePercentage={isMobile ? 80 : 70}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
        >
          {sortedItems &&
            sortedItems.map((item, i) => (
              <CarouselItem
                className="carousel-item"
                bg={item.visuel && item.visuel.sourceUrl}
                key={i}
              >
                <ImageConatiner
                  bg={item.visuel && item.visuel.sourceUrl}
                  className="image"
                ></ImageConatiner>
                <ContentContainer className="content">
                  <Date showFullDate={showFullDate}>
                    {showFullDate && (
                      <DayMonth>{moment(item.date).format("DD MMMM")}</DayMonth>
                    )}
                    <Year>{moment(item.date).format("YYYY")}</Year>
                  </Date>
                  <Intro
                    dangerouslySetInnerHTML={{
                      __html: item.titre,
                    }}
                  />
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: item.contenu,
                    }}
                  />
                </ContentContainer>
              </CarouselItem>
            ))}
        </Carousel>
      </HistoryCarouselWrapper>
    </React.Fragment>
  );
};

HistoryCarousel.propTypes = {};

HistoryCarousel.defaultProps = {};

export default HistoryCarousel;
